import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Login } from '../Auth/Login';
import { Link } from "react-router-dom";
import { dateFormation, getLocalStringNumber, isEmpty } from '../../Utils';
import { siteActions, userActions } from '../../store';
import { LogoutModal } from '../Common/modal';
import { resource_url } from '../../Utils/keys';

export { SideBar };
function SideBar() {

    const mainPageList = useSelector(state => state.site.mainPageList)
    const mainBannerImages = useSelector(state => state.site.mainBannerImages)
    const noticeLists = mainPageList.noticeLists
    const RecentArticleLists = mainPageList.RecentArticleLists
    const isAuthenticated = useSelector(state => state.user.isAuthenticated)
    const userData = useSelector(state => state.user.data)
    const dispatch = useDispatch();
    const [showLogout, setShowLogout] = useState(false);
    const onLogout = () => {
        setShowLogout(!showLogout)
    }

    const logout = () => {
        dispatch(userActions.logout());
        setShowLogout(!showLogout);
    }

    const getList = () => {
        dispatch(siteActions.getMainPageList());
        dispatch(siteActions.getMainBannerImages());
    }
    useEffect(() => {
        getList();
    }, []);
    return (
        <div className="sidebar_area">
            <div className="sidebar">
                <div className="latest_board">
                    <div className="tit_area">

                        <div className="tit">
                            <img src='../imgs/login/layer10.png' alt='notice' />
                            <span className="txt" style={{ marginLeft: "10px" }}>공지사항</span>
                        </div>
                    </div>
                    <ul>

                        <div className="main_box1 ">
                            <div className="table-box">
                                <ul>
                                    {!isEmpty(noticeLists) ?

                                        noticeLists.map((list, index) => {
                                            return (
                                                <li key={index}>
                                                    <div className="txt">
                                                        <Link to="/notice" title={list.title}>{list.title}</Link>
                                                    </div>
                                                    <div className="date">{dateFormation(list.created_date, 4)}</div>
                                                </li>
                                            )
                                        })
                                        :
                                        <li>
                                            <div className="txt">
                                                <div className="date" style={{ float: "left" }}>내역이 없습니다.</div>
                                            </div>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </ul>
                </div>
                {!isAuthenticated &&
                    <Login />
                }
                {isAuthenticated &&
                    <div className="welcome">
                        <div className="headline">
                            <div className="nickname">
                                <div className="txt">
                                    <span><div className="user_levelname"><span className="lv_icon_box"><div className={`lv_icon lv_icon${userData.level > 6 ? 1 : userData.level}`}></div></span><span className="user_name">{userData.name}</span></div></span>
                                    <span style={{ paddingTop: "3px" }}>님 환영합니다</span>
                                    <span style={{ paddingTop: "3px" }}><Link to="/notification" className="msg_icon" title="쪽지함"><i className="fa-solid fa-envelope" aria-hidden="true"></i></Link></span>
                                    <button className="logout_icon" title="로그아웃" onClick={onLogout}>로그아웃</button>
                                </div>

                            </div>
                            <div className="currentvisitor">
                                <strong>포인트</strong>&nbsp;
                                <Link to="/point" className="admin_login" title="나의 포인트" id="mem_point">
                                    {getLocalStringNumber(userData.point)}
                                </Link>
                                &nbsp;<strong>골드</strong>
                                &nbsp;
                                <Link to="/pointchange" className="admin_login" title="포인트전환">{getLocalStringNumber(Math.floor(userData.gold))}</Link>
                                &nbsp;<strong>빅휠이용권</strong>
                                &nbsp;
                                <Link to="/roulette" className="admin_login" title="빅휠">{userData.bigwhellCnt}</Link>
                            </div>
                        </div>
                        <div className="bigwheel_img">
                            <Link to="/roulette" title="출석 빅휠 이벤트 바로가기">
                                <img src="../imgs/bt_bigwheel.gif" alt="빅휠이벤트" style={{ width: "100%" }} />
                            </Link>
                        </div>
                        <Link to="/pointmall" title="포인트몰"><div className="point_mall_btn">POINT MALL (포인트몰)</div></Link>
                        <ul>
                            <li><Link to="/mypage" title="마이페이지" className="menu">마이페이지</Link></li>
                            <li><Link to="/attendance" title="출석체크" className="menu"><div className="attendance_icon"><i className="fas fa-user-check" aria-hidden="true"></i></div>출석체크</Link></li>
                            <li><Link to="/notice" title="공지" className="menu"><div className="attendance_icon badge notification_num"></div>공지</Link></li>
                            <li><Link to="/couponhistory" title="쿠폰내역" className="menu">쿠폰내역</Link></li>
                        </ul>
                    </div>}
                <div className="point_banner">
                    <Link to="/liveevent" title="이벤트 게시판 바로가기"><img src="../imgs/event_banner.gif" alt="이벤트 게시판이 추가되었습니다." /></Link>
                </div>
                <div className="telegram">
                    <ul>
                        <li>pgslot1236.com</li>
                    </ul>
                </div>

                <div className="point_banner">
                    {mainBannerImages &&
                        mainBannerImages.map((item, index) => {
                            return (
                                <div className='main-banner-images' key={index} title={item.title}><img src={`${resource_url}/${item.url}`} alt={item.title} /></div>
                            )
                        })
                    }
                </div>

            </div>
            {showLogout &&
                <LogoutModal showLogout={showLogout} onLogout={onLogout} logout={logout} />
            }
            {

            }
        </div>
    );


}