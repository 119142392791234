import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { request } from '../Utils/api';

import { api_url } from '../Utils/keys';
import { pageActions } from './page.slice';
import { isEmpty, toastr, setAuthToken } from '../Utils/index';

// create slice

const name = 'community';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

// exports

export const communityActions = { ...slice.actions, ...extraActions };
export const communityReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        followArticleList: [],
        freeArticleList: [],
        slotGameReviewList: [],
        friendSiteReviewList: [],
        slotReviewVideo: [],
        bestReviewList: [],
        logoList: [],
    }
}

function createReducers() {
    return {
        setFollowArticleList,
        setFreeArticleList,
        setSlotGameReviewList,
        setBestReviewList,
        setFriendSiteReviewList,
        setSlotReviewVideo,
        setLogoList,

    };

    function setFollowArticleList(state, action) {
        state.followArticleList = action.payload;
    }
    function setFreeArticleList(state, action) {
        state.freeArticleList = action.payload;
    }
    function setSlotGameReviewList(state, action) {
        state.slotGameReviewList = action.payload;
    }
    function setBestReviewList(state, action) {
        state.bestReviewList = action.payload;
    }
    function setFriendSiteReviewList(state, action) {
        state.friendSiteReviewList = action.payload;
    }
    function setSlotReviewVideo(state, action) {
        state.slotReviewVideo = action.payload;
    }
    function setLogoList(state, action) {
        state.logoList = action.payload;
    }


}

function createExtraActions() {

    return {
        getFollowArticleList: getFollowArticleList(),
        getFreeArticleList: getFreeArticleList(),
        sendFreeArticle: sendFreeArticle(),
        getSlotGameReviewList: getSlotGameReviewList(),
        getFriendSiteReviewList: getFriendSiteReviewList(),
        sendSlotGameReview: sendSlotGameReview(),
        getBestReviewList: getBestReviewList(),
        sendFriendSiteReview: sendFriendSiteReview(),
        getSlotReviewVideo: getSlotReviewVideo(),
    };

    function getFollowArticleList() {
        return createAsyncThunk(
            `${name}/getFollowArticleList`,
            async function (params, { dispatch }) {
                dispatch(pageActions.setLoading(true));
                try {
                    request.post(api_url + "/api/Article/followArticles", params)
                        .then(data => {
                            dispatch(pageActions.setPage(data.data));
                            dispatch(communityActions.setFollowArticleList(data.data.list));
                            dispatch(pageActions.setLoading(false));

                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
    function getFreeArticleList() {
        return createAsyncThunk(
            `${name}/getFreeArticleList`,
            async function (params, { dispatch }) {
                dispatch(pageActions.setLoading(true));
                try {
                    request.post(api_url + "/api/Article/freeArticleList", params)
                        .then(data => {
                            dispatch(pageActions.setPage(data.data));
                            dispatch(communityActions.setFreeArticleList(data.data.list));
                            dispatch(pageActions.setLoading(false));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
    function sendFreeArticle() {
        return createAsyncThunk(
            `${name}/sendFreeArticle`,
            async function (params, { dispatch }) {
                dispatch(pageActions.setLoading(true));
                try {
                    request.post(api_url + "/api/Article/freeArticles", params)
                        .then(data => {
                            if (data.success) {
                                toastr.success(data.message);
                                dispatch(communityActions.getFreeArticleList({page: 1, perPage: 10, type: "", key: ""}))
                                dispatch(pageActions.setLoading(false));
                            }
                            else
                                toastr.warning(data.message);
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
    function sendSlotGameReview() {
        return createAsyncThunk(
            `${name}/sendSlotGameReview`,
            async function (params, { dispatch }) {
                dispatch(pageActions.setLoading(true));
                try {
                    request.post(api_url + "/api/Article/slotGameReview", params)
                        .then(data => {
                            if (data.success) {
                                toastr.success(data.message);
                                dispatch(communityActions.getSlotGameReviewList({page: 1, perPage: 10, type: "", key: ""}))
                                dispatch(pageActions.setLoading(false));
                            }
                            else
                                toastr.warning(data.message);
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
    function sendFriendSiteReview() {
        return createAsyncThunk(
            `${name}/sendFriendSiteReview`,
            async function (params, { dispatch }) {
                dispatch(pageActions.setLoading(true));
                try {
                    request.post(api_url + "/api/Article/cooperationReview", params)
                        .then(data => {
                            if (data.success) {
                                toastr.success(data.message);
                                dispatch(communityActions.getFriendSiteReviewList({page: 1, perPage: 10, type: "", key: ""}))
                                dispatch(pageActions.setLoading(false));
                            }
                            else
                                toastr.warning(data.message);
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
    function getSlotGameReviewList() {
        return createAsyncThunk(
            `${name}/getSlotGameReviewList`,
            async function (params, { dispatch }) {
                dispatch(pageActions.setLoading(true));
                try {
                    request.post(api_url + "/api/Article/slotGameReviewLists", params)
                        .then(data => {
                            dispatch(pageActions.setPage(data.data));
                            dispatch(communityActions.setSlotGameReviewList(data.data.list));
                            dispatch(communityActions.setLogoList(data.data.logoList))
                            dispatch(pageActions.setLoading(false));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
    function getBestReviewList() {
        return createAsyncThunk(
            `${name}/getBestReviewList`,
            async function (params, { dispatch }) {
                dispatch(pageActions.setLoading(true));
                try {
                    request.post(api_url + "/api/Article/bestreview", params)
                        .then(data => {
                            dispatch(pageActions.setPage(data.data));
                            dispatch(communityActions.setBestReviewList(data.data.list));
                            dispatch(pageActions.setLoading(false));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
    function getFriendSiteReviewList() {
        return createAsyncThunk(
            `${name}/getFriendSiteReviewList`,
            async function (params, { dispatch }) {
                dispatch(pageActions.setLoading(true));
                try {
                    request.post(api_url + "/api/Article/cooperationLists", params)
                        .then(data => {
                            dispatch(pageActions.setPage(data.data));
                            dispatch(communityActions.setFriendSiteReviewList(data.data.list));
                            dispatch(communityActions.setLogoList(data.data.logoList))
                            dispatch(pageActions.setLoading(false));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
    function getSlotReviewVideo() {
        return createAsyncThunk(
            `${name}/getSlotReviewVideo`,
            async function (params, { dispatch }) {
                dispatch(pageActions.setLoading(true));
                try {
                    request.post(api_url + "/api/Article/slotReviewVideo", params)
                        .then(data => {
                            dispatch(pageActions.setPage(data.data));
                            dispatch(communityActions.setSlotReviewVideo(data.data.list));
                            dispatch(pageActions.setLoading(false));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
}