import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Pagination } from '../Common';
import { RightSideBar, SideBar } from '../Layout';
import { eventActions, siteActions } from '../../store';
import { resource_url } from '../../Utils/keys';
import { dateFormation, isEmpty } from '../../Utils';
import { NoticeDetail } from './components';

export { NoticeContent };

function NoticeContent() {

    const dispatch = useDispatch();
    const noticeList = useSelector(state => state.event.noticeList);

    const [showDetail, setShowDetail] = useState(false);

    const [active, setActive] = useState(0);

    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });

    const [searchInputs, setSearchInputs] = useState({
        searchType: "",
    });

    const { searchType } = searchInputs;

    const onChange = (e) => {
        const { name, value } = e.target;
        setSearchInputs({
            ...searchInputs,
            [name]: value
        })
        if (name === "searchType") {
            const param = {
                searchType: value,
            }
            getList(1, pageInfo.perPage, param);
        }
    }

    const onShowDetail = (index) => {
        setShowDetail(true)
        setActive(index)
        getAnswerList(1, 10, index)
    }

    const onBtnClick = () => {
        setShowDetail(!showDetail)
        setActive(-1)
    }


    const getList = (page = 1, perPage = 10, search = searchInputs) => {
        setPageInfo({
            ...pageInfo,
            curPage: page,
            perPage: Number(perPage)
        })
        setSearchInputs(search)
        const params = {
            page: page,
            perPage: perPage,
            type: search.searchType,
        }
        dispatch(eventActions.getNoticeList(params));
    }
    useEffect(() => {
        getList();
    }, []);

    const getAnswerList = (page = 1, perPage = 10, index = active) => {
        setPageInfo({
            ...pageInfo,
            curPage: page,
            perPage: Number(perPage)
        });
        const params = {
            type: "notice",
            typeId: noticeList[index].id,
            page: page,
            perPage: perPage,
        }
        dispatch(siteActions.getAnswerList(params));

    }


    return (
        <div className="container">
            <div className="row" style={{ margin: "0" }}>
                {showDetail && <NoticeDetail
                    length={noticeList.length}
                    active={active}
                    onBtnClick={onBtnClick}
                    setActive={setActive}
                    pageInfo={pageInfo}
                    noticeList={noticeList[active]}
                    getList={getAnswerList}
                />
                }
                {!showDetail &&
                    <div className="main_area">
                        <div className="board">
                            <div style={{padding: "0px 5px"}}>
                                <table className="table table-hover normal_board table-bordered">
                                    <thead>
                                        <tr>
                                            <th> 번호 </th>
                                            <th>이미지</th>
                                            <th>제목</th>
                                            <th>날짜</th>
                                            <th>문의결과</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {noticeList &&
                                            noticeList.map((list, index) => {
                                                return (
                                                    <tr className="notice_list important" key={index} onClick={() => onShowDetail(index)}>
                                                        <td>
                                                            <span className="notice_state">공지</span>
                                                        </td>
                                                        <td className="imgs">
                                                            <img src={`${resource_url}/${list.titleImg}`} alt={list.title} /></td>
                                                        <td className="center">
                                                            <Link to="" title={list.title}>{list.title}</Link>
                                                        </td>
                                                        <td>{dateFormation(list.created_date, 9)}</td>
                                                        <td>-</td>
                                                    </tr>
                                                )
                                            })

                                        }

                                    </tbody>
                                    {
                                        isEmpty(noticeList) &&
                                        <tbody>
                                            <tr>
                                                <td colSpan="5" className="nopost">내역이 없습니다</td>
                                            </tr>
                                        </tbody>
                                    }
                                </table>
                            </div>
                            {!isEmpty(noticeList) &&
                                <Pagination pageInfo={pageInfo} getList={getList} />
                            }
                        </div>
                    </div>
                }

                <SideBar />
            </div>
        </div>
    );

}