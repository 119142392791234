import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { userActions } from '../../store';
import { Pagination } from '../Common';
import { dateFormation, isEmpty, reComType } from '../../Utils';

export { MyRecommendationContent };

function MyRecommendationContent() {

    const dispatch = useDispatch();
    const recommendHistory = useSelector(state => state.user.recommendHistory);
    console.log('recommendHistory: ', recommendHistory);

    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });

    const getSlotList = (page = 1, perPage = 10) => {
        setPageInfo({
            ...pageInfo,
            curPage: page,
            perPage: Number(perPage)
        })
        const params = {
            page: page,
            perPage: perPage,
        }
        dispatch(userActions.getRecommendHistory(params));
    }


    useEffect(() => {
        getSlotList();
    }, []);
    return (
        <>
            <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>번호</th>
                        <th>구분</th>
                        <th>제목</th>
                        <th>날짜</th>
                    </tr>
                </thead>
                <tbody>
                    {!isEmpty(recommendHistory) ?
                        recommendHistory.map((list, index) => {
                            return (
                                <tr key={index} >
                                    <td>{Number(pageInfo.perPage) * (Number(pageInfo.curPage) - 1) + index + 1}</td>
                                    <td>{reComType(list.type)}</td>
                                    <td>{list.title}</td>
                                    <td>{dateFormation(list.created_date, 9)}</td>
                                </tr>
                            )
                        }) :
                        <tr>
                            <td colSpan="4">내역이 없습니다</td>
                        </tr>
                    }

                </tbody>
            </table>
            <Pagination pageInfo={pageInfo} getList={getSlotList} />
        </>
    );
}
