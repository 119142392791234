import React, { useState, useEffect, useRef } from 'react';
import { siteActions } from '../../store';
import { useSelector, useDispatch } from "react-redux";

import { Pagination } from '../Common';
import { api_url, resource_url } from '../../Utils/keys';
import { ChangeNum_Letter, dateFormation, getLocalStringNumber, isEmpty } from '../../Utils';
import { FakeDetail } from './components';
import { request } from '../../Utils/api';
import { RightSideBar, SideBar } from '../Layout';


export { FakeSiteContent };

function FakeSiteContent() {

    const dispatch = useDispatch();
    const fakeList = useSelector(state => state.site.fakeList);

    const fakeSiteRef = useRef();

    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });

    const [showDetail, setShowDetail] = useState(false);

    const [active, setActive] = useState(0)

    const [searchInputs, setSearchInputs] = useState({
        searchType: "",
        searchText: "",
    });

    const { searchType, searchText } = searchInputs;

    const onChange = (e) => {
        const { name, value } = e.target;
        setSearchInputs({
            ...searchInputs,
            [name]: value
        })
        if (name === "searchType") {
            const param = {
                searchType: value,
                searchText: searchText,
            }
            getList(1, pageInfo.perPage, param);
        }
    }

    const onShowDetail = (index) => {
        setShowDetail(true)
        setActive(index)
        getAnswerList(1, 10, index)
        const params = {
            type: "FakeSites",
            typeId: fakeList[index].id,
        }
        request.post(api_url + "/api/Article/viewcount", params)
    }

    const onBtnClick = () => {
        setShowDetail(!showDetail)
        setActive(-1)
    }

    const onSearch = () => {
        const param = {
            ...searchInputs
        }
        getList(1, pageInfo.perPage, param);
    }
    const onEnter = (e) => {
        if (e.key === "Enter") {
            onSearch();
        }
    }

    const getAnswerList = (page = 1, perPage = 10, index = active) => {
        setPageInfo({
            ...pageInfo,
            curPage: page,
            perPage: Number(perPage)
        });
        const params = {
            type: "FakeSites",
            typeId: fakeList[index].id,
            page: page,
            perPage: perPage,
        }
        dispatch(siteActions.getAnswerList(params));

    }

    const getList = (page = 1, perPage = 10, search = searchInputs) => {
        setPageInfo({
            ...pageInfo,
            curPage: page,
            perPage: Number(perPage)
        });
        setSearchInputs(search)
        const params = {
            page: page,
            perPage: perPage,
            type: search.searchType,
            key: search.searchText,
        }
        dispatch(siteActions.getFakeList(params));
    }
    useEffect(() => {
        getList();
    }, []);

    return (
        <div className="container">
            <div className="row" style={{ margin: "0" }}>
                <div className='main_area'>
                    {showDetail &&
                        <FakeDetail
                            onBtnClick={onBtnClick}
                            getList={getAnswerList}
                            fakeList={fakeList[active]}
                            pageInfo={pageInfo}
                            setPageInfo={setPageInfo}
                            setActive={setActive}
                            active={active}
                            length={fakeList.length}
                        />
                    }
                    {!showDetail &&
                        <>
                            <div className="page_seo_tit_box">
                                <h2>최신 카지노 먹튀 사이트 정보</h2>
                                <p>최신 카지노 먹튀 사이트 정보에서는 온라인 카지노 이용자들을 위해 먹튀 사이트의 최신 목록을 제공합니다.
                                    이 목록은 신뢰할 수 있는 검증과정을 거친 후 공개되며, 먹튀 사이트의 주요 특징과 그 피해를 방지할 수 있는
                                    방법도 함께 안내합니다.
                                    이 정보를 통해 이용자들은 안전하게 카지노 게임을 즐길 수 있습니다.
                                    주기적으로 업데이트되니, 자주 확인하여 먹튀 사이트로부터 스스로를 보호하세요.</p>
                            </div>
                            <div className="board">
                                <div className="board_form_box">
                                    <div className="search_area">
                                        <div className="searchbox">
                                            <div>
                                                <span><input type="text" placeholder="검색" name="searchText" value={searchText} onChange={onChange} onKeyDown={onEnter} /></span>
                                                <span><button onClick={onSearch}><i className="fa fa-search" aria-hidden="true"></i></button></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div action="../board/mukti" name="fboardlist" id="fboardlist" method="post" acceptCharset="utf-8">
                                    <div className="gallery_list">
                                        <ul style={{ display: "flex", flexWrap: "wrap" }}>
                                            {fakeList &&
                                                fakeList.map((list, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <div className="box">
                                                                <div className="mukti"><i className="fa fa-skull-crossbones" aria-hidden="true"></i> {ChangeNum_Letter(list.fakeMoney)}</div>

                                                                <div className="imgs">
                                                                    <div className="cursor-pointer" title={list.siteName} onClick={() => onShowDetail(index)} >
                                                                        <img src={`${resource_url}/${list.image}`} alt={list.siteName} style={{ width: '100%', height: "183px" }} />
                                                                    </div>
                                                                </div>
                                                                <div className="txt">
                                                                    <h3>{list.siteName}</h3>

                                                                    <div className="data">
                                                                        <dl>
                                                                            <dd>
                                                                                <span className="icon"><i className="fa fa-clock-o mr10" aria-hidden="true"></i></span>
                                                                                <span className="con">{dateFormation(list.created_date, 1)}</span>
                                                                            </dd>

                                                                            <dd>
                                                                                <span className="con">{list.siteNameKor}</span>
                                                                            </dd>
                                                                            <dd>
                                                                                <span className="icon"><i className="fa fa-eye" aria-hidden="true"></i></span>
                                                                                <span className="con">{list.viewCnt}</span>
                                                                            </dd>
                                                                        </dl>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </li>
                                                    )
                                                })

                                            }
                                            {isEmpty(fakeList) &&
                                                <li>
                                                    <div className="box">
                                                        <div className="txt">
                                                            <h3>내역이 없습니다.</h3>
                                                        </div>

                                                    </div>
                                                </li>
                                            }
                                        </ul>

                                    </div>
                                </div>
                                {!isEmpty(fakeList) &&
                                    <Pagination pageInfo={pageInfo} />
                                }
                            </div>
                            <div className="page_seo_tit_box">
                                <h2>온라인 카지노 먹튀 사이트는 반드시 피하세요</h2>
                                <p>투명한 정보 공개와 철저한 검증 절차를 통해 먹튀 사이트를 신중하게 선별하고, 베팅을 즐기시는 분들에게 안전한 환경을
                                    제공하고자 노력하고 있습니다.
                                    끝없는 "먹튀 문제로 고민이신가요?" 전문 먹튀 검증 업체가 많아져도, 일반적인 방법으로 먹튀를 잡는 것이 의미가
                                    없는 상황도 많습니다.
                                    그러나 대부분의 먹튀 사이트들은 도메인을 변경하더라도 동일한 서버에서 운영하기 때문에, 특정 신호를 통해 먹튀
                                    가능성을 확인할 수 있습니다.

                                    PG슬롯은 유저들의 제보와 신빙성 있는 데이터를 기반으로 먹튀 사이트를 엄선해 업데이트하고 있습니다.
                                    먹튀 피해자들의 증거자료와 사실 관계를 분석하여 정보를 공개하고 있습니다.

                                    국내 먹튀 피해로 해외 온라인 카지노 사이트를 이용하는 경우가 증가하고 있습니다. 그렇다면 해외 사이트는
                                    안전할까요?
                                    사실 그것도 미지수입니다. 해외에도 먹튀 사이트가 상당수 있으며, 그 피해 사례는 더욱 심각합니다.
                                    특히, IP 중복, 당첨 상한 초과 등 다양한 핑계로 환전을 거부하는 악의적인 사례가 빈번합니다.

                                    PG슬롯은 다양한 특징과 방법으로 이루어진 먹튀 사이트를 분석하고 있습니다.
                                    명칭만 변경해 재오픈하는 사이트, 꽁머니를 유도하는 카지노 사이트, 아무런 문제 없이 베팅을 이끌어내고 마지막에는
                                    다양한 사유로 환전을 거부하는 사이트 등을 철저히 분석하고 있습니다.
                                    따라서, 안정적인 자본력을 갖춘 사이트에서만 베팅을 하여 자산을 보호하실 수 있도록 도와드립니다.</p>
                            </div>

                            <div className="page_seo_tit_box qna_box">
                                <h2>카지노 먹튀 사이트 Q&amp;A</h2>

                                <div className="section_box">
                                    <h3>카지노 먹튀 사이트란 무엇인가요?</h3>
                                    <p>먹튀 사이트란 온라인 게임이나 베팅, 카지노 등에서 사용자의 자금을 빼돌리거나 약속된 서비스와 보상을 제공하지
                                        않는 부정한 웹사이트를 의미합니다.
                                        이런 사이트는 사용자가 입금을 하면 그 자금을 환불하지 않거나, 게임에 승리했을 경우 약속된 상금을 주지
                                        않는 등 다양한 방법으로 사용자를 속입니다.
                                        먹튀 사이트는 전문 용어로 '먹튀'를 하여, 이용자의 자금이나 데이터를 먹고 도망가는 행위를 하는 것이
                                        특징입니다.
                                        이러한 사이트들은 인터넷 상에서 활발하게 활동하고 있어, 먹튀 사이트를 피하고 안전한 이용을 위해 여러가지
                                        신뢰할 수 있는 정보와 리소스를 찾아볼 필요가 있습니다.
                                    </p>
                                </div>

                                <div className="section_box">
                                    <h3>먹튀 사이트를 어떻게 구별할 수 있나요?</h3>
                                    <ul>
                                        <li>✔ 리뷰와 평가 확인 : 인터넷 상에는 다양한 카지노와 베팅 사이트에 대한 리뷰와 평가가 있습니다.
                                            이를 통해 카지노 사이트의 신뢰성을 파악할 수 있습니다.</li>
                                        <li>✔ 라이센스 확인 : 합법적인 온라인 카지노나 베팅 사이트는 슬롯 라이센스 확인 가능합니다. 라이센스
                                            정보가 명확하게 표시되어 있는지 정품인지 확인하세요.</li>
                                        <li>✔ 약관과 조건 : 카지노 먹튀 사이트는 일반적으로 약관과 조건, 또는 환불 정책 등이 불명확하거나
                                            애매한 표현을 사용합니다. 이러한 사항들을 철저히 검토하세요.</li>
                                        <li>✔ 고객 서비스 : 신뢰할 수 있는 사이트는 고객 서비스가 원활합니다. 문의사항에 대한 답변이 빠르고
                                            명확한지, 연락이 쉬운지 등을 확인하세요.</li>
                                        <li>✔ 이벤트 비율 : 일반적으로 높은 이벤트 비율을 과장하여 알리는 사이트는 대부분 먹튀 사이트이므로
                                            반드시 주의가 필요합니다.</li>
                                        <li>✔ 사이트 디자인과 구성 : 신뢰할 수 있는 사이트는 일반적으로 전문가에 의해 잘 디자인되고
                                            구성됩니다. 먹튀 사이트는 종종 엉성하거나 신뢰하기 어려운 디자인을 사용합니다.</li>
                                        <li>✔ 커뮤니티 정보 : 구글이나 다른 검색 엔진을 사용해 사이트 이름과 '먹튀' 또는 '사기'와 같은
                                            키워드로 검색해 보고, 사용자들의 경험과 의견에 대한 정보를 공유하세요.</li>
                                        <li>✔ 자체 판단 : 마지막으로, 자신의 직감에 의존하는 것도 중요합니다. 뭔가 수상하다고 느껴지면 그
                                            사이트는 피하는 것이 좋습니다.</li>
                                    </ul>
                                </div>
                                <div className="section_box">
                                    <h3>온라인 카지노에서 먹튀 당한 경우 어떻게 해야 하나요?</h3>
                                    <ul>
                                        <li>✔ 스크린샷 캡처: 사이트 내의 모든 중요한 페이지, 입출금 기록, 베팅 기록, 약관 등을
                                            스크린샷으로 캡처합니다. 날짜와 시간이 표시되도록 하는 것이 좋습니다.</li>
                                        <li>✔ 메시지 기록: 먹튀 사이트와의 텔레그램, 메시지, 채팅 기록을 저장합니다. 이러한 기록은 나중에
                                            문제가 발생했을 때 중요한 증거가 될 수 있습니다.</li>
                                        <li>✔ 회원 정보 및 계정 정보: 회원 가입시 제출한 정보와 계정의 상태를 확인하시기 바랍니다.</li>
                                        <li>✔ 결제 기록: 결제를 했던 방법과 금액, 그리고 이를 확인할 수 있는 결제 내역 화면 등을
                                            캡처합니다.</li>
                                        <li>✔ 공식 문서: 이용 약관, 개인정보 처리방침 등 사이트에서 제공하는 모든 공식 문서를 다운로드 받아
                                            둡니다.</li>
                                        <li>✔ 기타 증거: 상황에 따라 추가적인 증거가 필요할 수 있으니, 가능한 모든 정보를 수집하는 것이
                                            좋습니다.</li>
                                    </ul>
                                    <p>이러한 증거는 먹튀 사이트를 신고하거나 조치를 취할 때 매우 중요한 역할을 할 수 있습니다. 따라서 가능한
                                        철저하게 수집해야 합니다.</p>
                                </div>
                                <div className="section_box">
                                    <h3>먹튀 사이트 신고는 어디서 할 수 있나요?</h3>
                                    <p>국내에서는 여러 온라인 카지노 커뮤니티와 포럼이 있으며 이러한 곳에 먹튀 사이트 정보를 공유하여 다른
                                        사용자들을 경고할 수 있습니다.
                                        특히 먹튀 검증을 목적으로 하는 먹튀 검증 사이트가 있습니다. 이러한 사이트에 신고를 하면, 전문가들이 해당
                                        사이트를 검증하고 결과를 공유해 드리며,
                                        먹튀 사이트 정보를 널리 퍼뜨릴 수 있습니다. 이를 통해 많은 사람들이 먹튀 사이트에 당하지 않을 수
                                        있습니다.
                                        이러한 방법으로 먹튀 사이트를 신고하면, 최소한 다른 이용자들이 피해를 보는 것을 방지할 수 있습니다.
                                        추가적으로, 먹튀를 당하셨다면 'PG슬롯'에서도 먹튀 신고를 받고 있습니다. 언제든지 1:1 문의를 주시면
                                        적극적으로 도와드리겠습니다.</p>
                                </div>
                                <div className="section_box">
                                    <h3>PG슬롯의 먹튀 사이트 목록은 신뢰성이 있나요?</h3>
                                    <p>저희 PG슬롯에서는 먹튀 사이트 목록을 지속적으로 업데이트하고 있습니다.
                                        이 과정은 유저들의 신고와 믿을 만한 파트너 업체의 정보를 기반으로 진행됩니다.
                                        신고가 접수되면 그에 따른 증거와 자료를 철저히 검토한 뒤, 해당 사이트와 직접 연락을 취해 논의합니다.
                                        '왜 먹튀를 했는지', '회원과 어떠한 협상이 가능한지' 등을 논의하고, 합의점을 찾지 못할 경우 해당
                                        사이트는 즉시 먹튀 사이트로 등록됩니다.
                                        이러한 프로세스를 거치므로, PG슬롯의 먹튀 사이트 목록은 믿을 수 있습니다.</p>
                                </div>
                            </div>
                            <div className="page_seo_tit_box">
                                <h2>안전한 온라인 카지노 사이트 추천 목록</h2>
                                <ul>
                                    <li><h3>1. 스핑크스 카지노 사이트 (Sphinx Casino) - 신규 가입시 20%의 보너스를 지원하는
                                        카지노사이트</h3></li>
                                    <li><h3>2. 강남파티 슬롯 사이트 (GNPT Slot) - 슬롯 게임 전문, 사용자들에게 풍성한 보너스와
                                        혜택을 드리는 슬롯사이트</h3></li>
                                    <li><h3>3. 샌즈 카지노 (Sands Casino) - 제휴업체중 가장 오래된 믿을 수 있는 메이저
                                        카지노사이트</h3></li>
                                    <li><h3>4. 비투비 슬롯 (B2B Slot) - 강력한 재무를 기반으로 안정된 운영을 자랑하는 슬롯사이트</h3></li>
                                    <li><h3>5. 가토 (Gato Casino) - 유저들에게 매일 이벤트를 제공하는 인기 있는 카지노사이트</h3></li>
                                    <li><h3>6. 맥스윈 (Maxwin Casino) - 최고의 슬롯과 테이블 게임으로 완벽한 카지노 경험을
                                        제공하는 카지노사이트</h3></li>
                                    <li><h3>7. 터보 (Turbo Slot) - 제휴사이트 유일하게 꽁머니 및 최고의 이벤트를 제공하는
                                        슬롯사이트</h3></li>
                                    <li><h3>8. 올림푸스 (Olympus Casino) - 에볼루션 바카라에서 플레이어들이 가장 선호하는
                                        바카라사이트</h3></li>
                                    <li><h3>9. 레이브 (Rave Slot) - 흥미진진한 슬롯 게임과 특별한 보너스가 기다리는 슬롯사이트</h3></li>
                                </ul>
                            </div>
                        </>}
                </div>
                <SideBar />
            </div>

        </div>
    );
}