import { isEmpty } from "../Utils";
import { store } from "../store";


export const getgameGrps = (type) => {
  switch (type) {
    case "EVOPowerball":
      return evogameGrps;
  
    case "nuguri":
      return nugurigameGrps;
  
    case "soccer":
      return soccergameGrps;
  
    case "baseball":
      return baseballgameGrps;
  
    case "squidgame":
      return squidgameGrps;
  
    default:
      return eosgameGrps
  }
  }
  
  export const prices = (type) => {
  switch (type) {
    case "EVOPowerball":
      return evoballPrice();
  
    case "nuguri":
      return nuguriPrice();
  
    case "soccer":
      return soccerPrice();
  
    case "baseball":
      return baseballPrice();
  
    case "squidgame":
      return squidPrice();
  
    default:
      return eosballPrice()
  }
  }
  
  export const eosgameGrps = [
  {
    label: '일반볼',
    buttons: [
      {
        color: 'blue',
        name: '홀',
        name_other: '일반볼-홀',
        rate: 1.5,
        hasoption: false,
        odd_key: 'normal_odd'
      },
      {
        color: 'red',
        name: '짝',
        name_other: '일반볼-짝',
        rate: 1.5,
        hasoption: false,
        odd_key: 'normal_even'
      },
      {
        color: 'blue',
        name: '오버',
        name_other: '일반볼-오버',
        rate: 1.9,
        hasoption: true,
        option: '73~130',
        odd_key: 'normal_over'
      },
      {
        color: 'red',
        name: '언더',
        name_other: '일반볼-언더',
        rate: 1.9,
        hasoption: true,
        option: '00~72',
        odd_key: 'normal_under'
      }
    ]
  },
  {
    label: '게임 : 일반볼합 구간',
    iswide: true,
    buttons: [
      {
        color: 'blue',
        name: '대',
        rate: 1.5,
        hasoption: true,
        option: '81~130',
        odd_key: 'normal_big'
      },
      {
        color: 'green',
        name: '중',
        rate: 1.8,
        hasoption: true,
        option: '65~80',
        odd_key: 'normal_medium'
      },
      {
        color: 'red',
        name: '소',
        rate: 1.4,
        hasoption: true,
        option: '15~64',
        odd_key: 'normal_small'
      }
    ]
  },
  {
    label: '파워볼',
    buttons: [
      {
        color: 'blue',
        name: '홀',
        name_other: '파워볼-홀',
        rate: 1.2,
        odd_key: 'power_odd'
      },
      {
        color: 'red',
        name: '짝',
        name_other: '파워볼-짝',
        rate: 1.2,
        odd_key: 'power_even'
      },
      {
        color: 'blue',
        name: '오버',
        name_other: '파워볼-오버',
        rate: 1.6,
        hasoption: true,
        option: '05~09',
        odd_key: 'power_over'
      },
      {
        color: 'red',
        name: '언더',
        name_other: '파워볼-언더',
        rate: 1.6,
        hasoption: true,
        option: '00~04',
        odd_key: 'power_under'
      }
    ]
  }
  ]
  
  
  export const eosballPrice = () => {
  const rates = store.getState().mini.rates
  return [
    isEmpty(rates.normal) ? 1 : rates.normal.evenOdd,
    isEmpty(rates.normal) ? 1 : rates.normal.evenOdd,
    isEmpty(rates.normal) ? 1 : rates.normal.overUnder,
    isEmpty(rates.normal) ? 1 : rates.normal.overUnder,
    isEmpty(rates.normal) ? 1 : rates.normal.big,
    isEmpty(rates.normal) ? 1 : rates.normal.medium,
    isEmpty(rates.normal) ? 1 : rates.normal.small,
    isEmpty(rates.power) ? 1 : rates.power.evenOdd,
    isEmpty(rates.power) ? 1 : rates.power.evenOdd,
    isEmpty(rates.power) ? 1 : rates.power.overUnder,
    isEmpty(rates.power) ? 1 : rates.power.overUnder
  ]
  }
  
  export const evogameGrps = [
    {
      label: '일반볼',
      buttons: [
        {
          color: 'blue',
          name: '홀',
          name_other: '일반볼-홀',
          rate: 1.5,
          hasoption: false,
          odd_key: 'normal_odd'
        },
        {
          color: 'red',
          name: '짝',
          name_other: '일반볼-짝',
          rate: 1.5,
          hasoption: false,
          odd_key: 'normal_even'
        },
        {
          color: 'blue',
          name: '오버',
          name_other: '일반볼-오버',
          rate: 1.9,
          hasoption: true,
          option: '73~130',
          odd_key: 'normal_over'
        },
        {
          color: 'red',
          name: '언더',
          name_other: '일반볼-언더',
          rate: 1.9,
          hasoption: true,
          option: '00~72',
          odd_key: 'normal_under'
        }
      ]
    },
    {
      label: '일반볼합 구간',
      iswide: true,
      buttons: [
        {
          color: 'blue',
          name: '대',
          rate: 1.5,
          hasoption: true,
          option: '81~130',
          odd_key: 'normal_big'
        },
        {
          color: 'green',
          name: '중',
          rate: 1.8,
          hasoption: true,
          option: '65~80',
          odd_key: 'normal_medium'
        },
        {
          color: 'red',
          name: '소',
          rate: 1.4,
          hasoption: true,
          option: '15~64',
          odd_key: 'normal_small'
        }
      ]
    },
    {
      label: '파워볼',
      buttons: [
        {
          color: 'blue',
          name: '홀',
          name_other: '파워볼-홀',
          rate: 1.2,
          odd_key: 'power_odd'
        },
        {
          color: 'red',
          name: '짝',
          name_other: '파워볼-짝',
          rate: 1.2,
          odd_key: 'power_even'
        },
        {
          color: 'blue',
          name: '오버',
          name_other: '파워볼-오버',
          rate: 1.6,
          hasoption: true,
          option: '05~09',
          odd_key: 'power_over'
        },
        {
          color: 'red',
          name: '언더',
          name_other: '파워볼-언더',
          rate: 1.6,
          hasoption: true,
          option: '00~04',
          odd_key: 'power_under'
        }
      ]
    }
    ]
  
  
  export const evoballPrice = () => {
  const rates = store.getState().mini.rates
  return [
    isEmpty(rates.normal) ? 1 : rates.normal.evenOdd,
    isEmpty(rates.normal) ? 1 : rates.normal.evenOdd,
    isEmpty(rates.normal) ? 1 : rates.normal.overUnder,
    isEmpty(rates.normal) ? 1 : rates.normal.overUnder,
    isEmpty(rates.normal) ? 1 : rates.normal.big,
    isEmpty(rates.normal) ? 1 : rates.normal.medium,
    isEmpty(rates.normal) ? 1 : rates.normal.small,
    isEmpty(rates.power) ? 1 : rates.power.evenOdd,
    isEmpty(rates.power) ? 1 : rates.power.evenOdd,
    isEmpty(rates.power) ? 1 : rates.power.overUnder,
    isEmpty(rates.power) ? 1 : rates.power.overUnder
  ] 
  }
  
  export const nugurigameGrps = [
  {
    label: '홀짝',
    buttons: [
      {
        color: 'blue',
        name: '홀',
        rate: 1.5,
        hasoption: false,
        odd_key: 'odd'
      },
      {
        color: 'red',
        name: '짝',
        rate: 1.5,
        hasoption: false,
        odd_key: 'even'
      }
    ]
  },
  {
    label: '좌우',
    buttons: [
      {
        color: 'blue',
        name: '좌',
        rate: 1.9,
        hasoption: true,
        option: '',
        odd_key: 'nugurileft'
      },
      {
        color: 'red',
        name: '우',
        rate: 1.9,
        hasoption: true,
        option: '',
        odd_key: 'nuguriright'
      }
    ]
  },
  {
    label: '라인',
    iswide: true,
    buttons: [
      {
        color: 'blue',
        name: '라인3',
        rate: 1.5,
        hasoption: true,
        option: '81~130',
        odd_key: 'line3'
      },
      {
        color: 'red',
        name: '라인4',
        rate: 1.8,
        hasoption: true,
        option: '65~80',
        odd_key: 'line4'
      }
    ]
  }
  ]
  
  
  export const nuguriPrice = () => {
  const rates = store.getState().mini.rates
  return [
    isEmpty(rates.normal) ? 1 : rates.normal.odd,
    isEmpty(rates.normal) ? 1 : rates.normal.even,
    isEmpty(rates.normal) ? 1 : rates.normal.left,
    isEmpty(rates.normal) ? 1 : rates.normal.right,
    isEmpty(rates.normal) ? 1 : rates.normal.line3,
    isEmpty(rates.normal) ? 1 : rates.normal.line4
  ]
  }
  
  export const soccergameGrps = [
  {
    label: '키퍼',
    buttons: [
      {
        color: 'blue',
        name: '좌',
        name_other: '키퍼-좌',
        rate: 1.5,
        hasoption: false,
        odd_key: 'keeper_left'
      },
      {
        color: 'red',
        name: '우',
        name_other: '키퍼-우',
        rate: 1.5,
        hasoption: false,
        odd_key: 'keeper_right'
      }
    ]
  },
  {
    label: '키커',
    buttons: [
      {
        color: 'blue',
        name: '좌',
        name_other: '키커-좌',
        rate: 1.9,
        hasoption: true,
        option: '',
        odd_key: 'kicker_left'
      },
      {
        color: 'red',
        name: '우',
        name_other: '키커-우',
        rate: 1.9,
        hasoption: true,
        option: '',
        odd_key: 'kicker_right'
      }
    ]
  },
  {
    label: '골',
    iswide: true,
    buttons: [
      {
        color: 'blue',
        name: '좌',
        name_other: '골-좌',
        rate: 1.5,
        hasoption: true,
        option: '',
        odd_key: 'goal_left'
      },
      {
        color: 'red',
        name: '우',
        name_other: '골-우',
        rate: 1.8,
        hasoption: true,
        option: '',
        odd_key: 'goal_right'
      }
    ]
  }
  ]
  
  
  export const soccerPrice = () => {
  const rates = store.getState().mini.rates
  return [
    isEmpty(rates.keeper) ? 1 : rates.keeper.keeper_left,
    isEmpty(rates.keeper) ? 1 : rates.keeper.keeper_right,
    isEmpty(rates.keeper) ? 1 : rates.kicker.kicker_left,
    isEmpty(rates.keeper) ? 1 : rates.kicker.kicker_right,
    isEmpty(rates.goal) ? 1 : rates.goal.goal_left,
    isEmpty(rates.goal) ? 1 : rates.goal.goal_right
  ]
  }
  
  export const baseballgameGrps = [
  {
    label: '스트라이크',
    iswide: true,
    buttons: [
      {
        color: 'blue',
        name: '좌',
        name_other: '스트라이크-좌',
        rate: 1.5,
        hasoption: true,
        option: '',
        odd_key: 'strikeleft'
      },
      {
        color: 'green',
        name: '중',
        name_other: '스트라이크-중',
        rate: 1.8,
        hasoption: true,
        option: '',
        odd_key: 'strikemiddle'
      },
      {
        color: 'red',
        name: '우',
        name_other: '스트라이크-우',
        rate: 1.4,
        hasoption: true,
        option: '',
        odd_key: 'strikeright'
      }
    ]
  },
  {
    label: '홈란',
    iswide: true,
    buttons: [
      {
        color: 'blue',
        name: '좌',
        name_other: '홈란-좌',
        rate: 1.5,
        hasoption: true,
        option: '',
        odd_key: 'homerunleft'
      },
      {
        color: 'green',
        name: '중',
        name_other: '홈란-중',
        rate: 1.8,
        hasoption: true,
        option: '',
        odd_key: 'homerunmiddle'
      },
      {
        color: 'red',
        name: '우',
        name_other: '홈란-우',
        rate: 1.4,
        hasoption: true,
        option: '',
        odd_key: 'homerunright'
      }
    ]
  },
  ]
  
  
  export const baseballPrice = () => {
  const rates = store.getState().mini.rates
  return [
    isEmpty(rates.strike) ? 1 : rates.strike.left,
    isEmpty(rates.strike) ? 1 : rates.strike.right,
    isEmpty(rates.strike) ? 1 : rates.strike.middle,
    isEmpty(rates.homerun) ? 1 : rates.homerun.left,
    isEmpty(rates.homerun) ? 1 : rates.homerun.right,
    isEmpty(rates.homerun) ? 1 : rates.homerun.middle,
  ]
  }
  
  export const squidgameGrps = [
  {
    label: '좌우',
    iswide: true,
    buttons: [
      {
        color: 'blue',
        name: '좌',
        rate: 1.5,
        hasoption: false,
        odd_key: 'left'
      },
      {
        color: 'red',
        name: '우',
        rate: 1.5,
        hasoption: false,
        odd_key: 'right'
      }
    ]
  }
  ]
  
  
  export const squidPrice = () => {
  const rates = store.getState().mini.rates
  return [
    rates.normal.left,
    rates.normal.right,
  ]
  }
  
  export const minigameItems = [
  {
    category: 'eospowerball1',
    value: 1,
    name: 'EOS파워볼',
    url: 'eospowerball1'
  },
  {
    category: 'eospowerball2',
    value: 2,
    name: 'EOS파워볼',
    url: 'eospowerball2'
  },
  {
    category: 'eospowerball3',
    value: 3,
    name: 'EOS파워볼',
    url: 'eospowerball3'
  },
  {
    category: 'eospowerball4',
    value: 4,
    name: 'EOS파워볼',
    url: 'eospowerball4'
  },
  {
    category: 'eospowerball5',
    value: 5,
    name: 'EOS파워볼',
    url: 'eospowerball5'
  },
  {
    category: 'EVOPowerball',
    value: "EVOPowerball",
    name: 'EVO파워볼',
    url: 'EVOPowerball'
  },
  {
    category: 'nuguri',
    value: 'nuguri',
    name: '너구리',
    url: 'nuguri'
  },
  {
    category: 'soccer',
    value: 'soccer',
    name: '축구',
    url: 'soccer'
  },
  {
    category: 'baseball',
    value: 'baseball',
    name: '야구',
    url: 'baseball'
  },
  {
    category: 'squidgame',
    value: 'squidgame',
    name: '오징어',
    url: 'squidgame'
  }
  ];