import React, { Fragment } from 'react';
import { Navbar } from '../../Components/Layout/Navbar';
import { EOS3Content } from '../../Components/MiniGame';
import { RightSideBar, SideBar } from '../../Components/Layout';

export { EOS3 };


function EOS3() {
    return (
        <Fragment>
            <Navbar type="minigame" />
            <div className='main'>
                <div className="container">
                    <div className="row" style={{ margin: "0" }}>
                        <EOS3Content />
                        <SideBar />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
