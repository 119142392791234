import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { changeEditorValue, dateFormation, isEmpty, toastr } from '../../../Utils';
import { request } from '../../../Utils/api';
import { api_url } from '../../../Utils/keys';
import { Pagination } from '../../Common';

export { SlotReviewVideoDetail };


const SlotReviewVideoDetail = ({
    onBtnClick,
    setActive,
    active,
    length,
    slotReviewVideo,
    pageInfo,
    getList,
}) => {

    const userData = useSelector(state => state.user.data);
    const isAuthenticated = useSelector(state => state.user.isAuthenticated);
    const slotVideoAnswerList = useSelector(state => state.site.answerList);

    const onSetActive = (key) => {
        if (key < 0 || key >= length) {
            setActive(0);
            getList(1, 10, 0)
        } else {
            setActive(key);
            getList(1, 10, key)
        }
    }

    const [editArticle, setEditArticle] = useState("");

    const onChange = (e) => {
        setEditArticle(e.target.value)
    }

    const onArticleSend = () => {
        if (isEmpty(editArticle)) {
            return toastr.warning("댓글을 입력해주세요.")
        }
        const params = {
            type: "slotReviewVideo",
            answerList: editArticle,
            typeId: slotReviewVideo.id,
        }
        request.post(api_url + "/api/Answer/answerArticle", params)
            .then(data => {
                if (data.success) {
                    toastr.success(data.message)
                    getList(1, 10, active)
                    setEditArticle("")
                }
            })
    }

    const reCommendation = () => {
        if (!isAuthenticated) {
            toastr.warning("로그인하고 이용해주세요")
        } else {
            const params = {
                type: "slotReviewVideo",
                typeId: slotReviewVideo.id,
                title: slotReviewVideo.title,
            }
            request.post(api_url + "/api/Article/recomList", params)
                .then(data => {
                    if (data.success) {
                        toastr.success(data.message)
                    }
                })
        }
    }

    return (
        <div className="main_area">
            <div className="board review_video">
                <div className="contents-view">
                    <div id="post-content">

                        <div className="post_content" dangerouslySetInnerHTML={{ "__html": !isEmpty(slotReviewVideo.detail) ? changeEditorValue(slotReviewVideo.detail) : "" }}></div>
                        <div className="new_recommand">
                            <button className="good btn btn-sm " id="btn-post-like" title="추천하기" onClick={reCommendation}>
                                <i className="fa fa-thumbs-o-up fa-lg" aria-hidden="true"></i>
                                <span className="post-like">{slotReviewVideo.recomCnt}</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="clearfix"></div>

                {isAuthenticated &&
                    <div id="comment_write_box">
                        <div className="well comment_write_box_inner">
                            <div className="alert alert-auto-close alert-dismissible alert-comment-message" style={{ display: "none" }}><button
                                type="button" className="close alertclose">×</button><span className="alert-comment-message-content"></span>
                            </div>
                            <form action="https://slotbuff2.com/post/51422" name="fcomment" id="fcomment" method="post"
                                acceptCharset="utf-8" noValidate="novalidate">

                                <div className="comment_area">
                                    <ul>
                                        <li>
                                            <textarea
                                                className="comment_write"
                                                placeholder="광고, 심한욕설의 댓글은 경고없이 삭제됩니다."
                                                name="editArticle"
                                                id="editArticle"
                                                value={editArticle}
                                                onChange={onChange}
                                            >

                                            </textarea>

                                        </li>
                                        <li>
                                            <div role="group" aria-label="...">
                                                <button type="button" className="commet_btn" id="cmt_btn_submit" onClick={onArticleSend}>댓글 등록</button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="comment_write_button_area">

                                </div>
                            </form>
                        </div>
                    </div>
                }

                <div id="viewcomment">
                    <div className="alert alert-auto-close alert-dismissible alert-comment-list-message" style={{ display: "none" }}><button
                        type="button" className="close alertclose">×</button><span
                            className="alert-comment-list-message-content"></span></div>

                    {slotVideoAnswerList &&
                        slotVideoAnswerList.map((item, index) => {
                            return (
                                <div key={index} className="media" id="comment_201176" style={{ paddingLeft: "0%" }}>
                                    <div className="media-body">
                                        <div className="media-heading">
                                            <span className="name">
                                                <div className="user_levelname"><span className="lv_icon_box">
                                                    <div className={`lv_icon lv_icon${item.level > 6 ? 1 : item.level}`}></div>
                                                </span><span className="user_name">{item.loginId}</span></div>
                                            </span>
                                            <span className="time"><i className="fa fa-clock-o mr10" aria-hidden="true"></i>{dateFormation(item.created_date, 9)}</span>
                                        </div>
                                        <div className="media-content">{item.answerList}</div>
                                        <span id="edit_201176"></span>
                                        <span id="reply_201176"></span>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <Pagination
                        pageInfo={pageInfo}
                        getList={getList} />
                </div>

                <div className="border_button mt20 mb20">
                    <div className="btn-group" role="group" aria-label="..." style={{ display: "flex" }}>
                        <button className="btn btn-primary btn-sm" onClick={onBtnClick}>목록</button>
                        <button className="btn btn-primary btn-sm" onClick={() => onSetActive(active + 1)}>다음글</button>
                        <button className="btn btn-primary btn-sm" onClick={() => onSetActive(active - 1)}>이전글</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
