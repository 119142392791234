import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { request } from '../Utils/api';

import { api_url } from '../Utils/keys';
import { pageActions } from './page.slice';

// create slice

const name = 'site';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

// exports

export const siteActions = { ...slice.actions, ...extraActions };
export const siteReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        verifiedList: [],
        fakeList: [],
        verifiedArticleList: [],
        fakeArticleList: [],
        answerList: [],
        mainPageList: {},
        mainBanner: [],
        slotBanner: [],
        eventBanner: [],
        mainBannerImages: [],
    }
}

function createReducers() {
    return {
        setVerifiedList,
        setFakeList,
        setAnswerList,
        setVerifiedArticleList,
        setFakeArticleList,
        setMainPageList,
        setMainBannerUrl,
        setMainBannerImages
    };

    function setVerifiedList(state, action) {
        state.verifiedList = action.payload;
    }
    function setFakeList(state, action) {
        state.fakeList = action.payload;
    }
    function setVerifiedArticleList(state, action) {
        state.verifiedArticleList = action.payload;
    }
    function setFakeArticleList(state, action) {
        state.fakeArticleList = action.payload;
    }
    function setAnswerList(state, action) {
        state.answerList = action.payload;
    }
    function setMainPageList(state, action) {
        state.mainPageList = action.payload;
    }
    function setMainBannerUrl(state, action) {
        state.mainBanner = action.payload;
    }
    function setMainBannerImages(state, action) {
        state.mainBannerImages = action.payload;
    }

}

function createExtraActions() {

    return {
        getVerifiedList: getVerifiedList(),
        getFakeList: getFakeList(),
        getAnswerList: getAnswerList(),
        getMainPageList: getMainPageList(),
        getMainBannerUrl: getMainBannerUrl(),
        getMainBannerImages: getMainBannerImages(),

    };

    function getVerifiedList() {
        return createAsyncThunk(
            `${name}/getVerifiedList`,
            async function (params, { dispatch }) {
                dispatch(pageActions.setLoading(true));
                try {
                    request.post(api_url + "/api/SiteList/getVerifySites")
                        .then(data => {
                            dispatch(siteActions.setVerifiedList(data.data.list));
                            dispatch(pageActions.setPage(data.data));
                            dispatch(siteActions.setVerifiedArticleList(data.data.answerLists));
                            dispatch(pageActions.setLoading(false));

                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
    function getFakeList() {
        return createAsyncThunk(
            `${name}/getFakeList`,
            async function (params, { dispatch }) {
                dispatch(pageActions.setLoading(true));
                try {
                    request.post(api_url + "/api/SiteList/getFakeSites", params)
                        .then(data => {
                            dispatch(siteActions.setFakeList(data.data.list));
                            dispatch(pageActions.setPage(data.data));
                            dispatch(siteActions.setFakeArticleList(data.data.answerLists));
                            dispatch(pageActions.setLoading(false));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
    function getAnswerList() {
        return createAsyncThunk(
            `${name}/getAnswerList`,
            async function (params, { dispatch }) {
                dispatch(pageActions.setLoading(true));
                try {
                    request.post(api_url + "/api/SiteList/answerList", params)
                        .then(data => {
                            dispatch(pageActions.setPage(data.data));
                            dispatch(siteActions.setAnswerList(data.data.answerLists));
                            dispatch(pageActions.setLoading(false));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
    function getMainPageList() {
        return createAsyncThunk(
            `${name}/getMainPageList`,
            async function (params, { dispatch }) {
                dispatch(pageActions.setLoading(true));
                try {
                    request.post(api_url + "/api/Main/mainPage", params)
                        .then(data => {
                            dispatch(siteActions.setMainPageList(data.data));
                            dispatch(pageActions.setLoading(false));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
    function getMainBannerUrl() {
        return createAsyncThunk(
            `${name}/getMainBannerUrl`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/Main/mainBanner")
                        .then(data => {
                            dispatch(siteActions.setMainBannerUrl(data.data));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
    function getMainBannerImages() {
        return createAsyncThunk(
            `${name}/getMainBannerImages`,
            async function (params, { dispatch }) {
                try {
                    request.post(api_url + "/api/Main/BannerImages")
                        .then(data => {
                            dispatch(siteActions.setMainBannerImages(data.data.list));
                        })
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }
}