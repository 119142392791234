import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import { RightSideBar, SideBar } from '../Layout';

export { MyPageMenu };


function MyPageMenu(props) {

    return (
        <div className="container">
            <div className="row" style={{ margin: "0" }}>
                <div className="main_area">
                    <div className="m_nav_box my_info_box">
                        <div className="frame">
                            <ul className="slidee">
                                <li>
                                    <Link to="/notification" title="쪽지">
                                        <div className="icon"><i className="fa-sharp fa-solid fa-envelope" aria-hidden="true"></i></div>
                                        <div className="txt">쪽지</div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/attendance" title="출석체크">
                                        <div className="icon"><i className="fa-solid fa-user-check" aria-hidden="true"></i></div>
                                        <div className="txt">출석체크</div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/notice" title="공지">
                                        <div className="icon"><i className="fa-brands fa-facebook-messenger" aria-hidden="true"></i></div>
                                        <div className="txt">공지</div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/pointmall" title="쿠폰">
                                        <div className="icon"><i className="fa-solid fa-ticket-simple" aria-hidden="true"></i></div>
                                        <div className="txt">쿠폰</div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/pointchange" title="포인트전환">
                                        <div className="icon"><i className="fa-solid fa-p" aria-hidden="true"></i></div>
                                        <div className="txt">포인트전환</div>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="mypage">
                        <ul className="tab_menu_box">
                            <li className="">
                                <NavLink to="/mypage" title="마이페이지">마이페이지</NavLink>
                            </li>
                            <li>
                                <NavLink to="/mypost" title="나의 작성글">나의 작성글</NavLink>
                            </li>
                            <li>
                                <NavLink to="/point" title="포인트">포인트</NavLink>
                            </li>
                            <li>
                                <NavLink to="/myrecommendation" title="내가 추천한 글">추천</NavLink>
                            </li>
                            <li>
                                <NavLink to="/mylogs" title="나의 로그인기록">로그인기록</NavLink>
                            </li>
                            <li>
                                <NavLink to="/myinfo" title="정보수정">정보수정</NavLink>
                            </li>
                            <li>
                                <NavLink to="/leave" title="탈퇴하기">탈퇴하기</NavLink>
                            </li>
                        </ul>
                        {props.children}
                    </div>
                </div>
                <SideBar />
            </div>
        </div>
    );
}
