import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { dateFormation } from '../../Utils';
import { LogoutModal } from '../Common/modal';
import { userActions } from '../../store';

export { MyPageContent };

function MyPageContent() {

    const userData = useSelector(state => state.user.data);
    const dispatch = useDispatch();
    const [showLogout, setShowLogout] = useState(false);
    const onLogout = () => {
        setShowLogout(!showLogout)
    }

    const logout = () => {
        dispatch(userActions.logout());
        setShowLogout(!showLogout);
    }
    return (
        <div className="form-box">
            <div className="form-group">
                <label className="col-sm-3 control-label">아이디</label>
                <div className="col-sm-9">
                    <p className="form-control-static">{userData.loginId}</p>
                </div>
            </div>
            <div className="form-group">
                <label className="col-sm-3 control-label">메일</label>
                <div className="col-sm-9">
                    <p className="form-control-static">{userData.email}</p>
                </div>
            </div>
            <div className="form-group">
                <label className="col-sm-3 control-label">닉네임</label>
                <div className="col-sm-9">
                    <p className="form-control-static">{userData.name}</p>
                </div>
            </div>
            <div className="form-group" style={{ display: "none" }}>
                <label className="col-sm-3 control-label">포인트</label>
                <div className="col-sm-9">
                    <p className="form-control-static">{userData.point}</p>
                </div>
            </div>
            <div className="form-group">
                <label className="col-sm-3 control-label">가입일</label>
                <div className="col-sm-9">
                    <p className="form-control-static">{dateFormation(userData.login_date, 9)}</p>
                </div>
            </div>
            <div className="form-group">
                <label className="col-sm-3 control-label">최근 로그인</label>
                <div className="col-sm-9">
                    <p className="form-control-static">{dateFormation(userData.signup_date, 5)}</p>
                </div>
            </div>
            <div className="form-group">
                <div className="col-sm-9 col-sm-offset-3 mt20">
                    <button className="new_btn logout" title="로그아웃" onClick={onLogout}>로그아웃</button>
                </div>
            </div>
            {showLogout &&
                <LogoutModal showLogout={showLogout} onLogout={onLogout} logout={logout} />
            }
        </div>
    );
}