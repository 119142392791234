import React, { useState } from 'react';
import { isEmpty } from '../../Utils';


export { HomeFAQ };

const questionList = [
    {
        title: `- 온라인 슬롯 사이트 보증 업체는 어디가 좋은가요?`,
        content: `온라인 슬롯 게임을 즐기시는 분들 중, 슬롯사이트를 선택할 때 가장 우선 고려하는 요소는 무엇인가요? 여러 선택지 중에서 신뢰성과 안전성은 매우 중요한 요소입니다. 사용자들이 안심하고 게임을 즐기기 위해서는 믿을 수 있는 슬롯사이트를 찾아야 합니다.안전한 슬롯 사이트를 선택할 때 몇 가지 기준을 고려하시면 좋습니다.`,
        contentOne: [
            {
                head: `1. 면허와 인증`,
                con: ` : 신뢰할 수 있는 슬롯사이트는 공식적인 라이센스를 보유하고 있습니다. 이는 사이트가 규정을 준수하고 있다는 증거입니다.`
            },
            {
                head: `2. 사용자 리뷰`,
                con: ` : 슬롯 커뮤니티에서의 리뷰와 평가를 통해 다른 사용자들의 경험을 살펴보는 것이 좋습니다.`,
            },
            {
                head: `3. 고객 지원`,
                con: ` : 24시간 운영되는 고객 지원 서비스가 있는지 확인하세요. 문제 발생 시 빠르게 도움을 받을 수 있어야 합니다.`,
            },
        ],
        tip: `팁: 슬롯사이트 선택 시, 여러 사이트를 비교해보는 것이 중요합니다. 각 사이트의 장단점을 파악하여 자신에게 맞는 곳을 선택하세요.`,
        contenttwo: `슬롯사이트의 신뢰성을 확보한 후, 다음으로는 어떤 게임을 선택할지 고민해 보아야 합니다. 이후의 슬롯 게임을 통해 이익을 얻을 수 있는지에 대한 의문이 생길 수 있습니다. 이처럼 슬롯사이트 선택은 앞으로의 게임 경험에 큰 영향을 미치므로, 충분한 정보를 통해 현명한 결정을 내리는 것이 필요합니다.`
    },
    {
        title: `- 슬롯 사이트 게임을 통해 이익을 얻을 수 있을까요?`,
        content: `놀라운 사실은, 슬롯 게임의 수익률(RTP)이 평균적으로 90%에서 98% 범위에 있다는 점입니다. 이는 슬롯사이트에서 재미를 느끼는 것 외에도 이익을 얻을 가능성이 있다는 것을 시사합니다. 하지만 모든 슬롯 게임이 동일한 수익 구조를 가지고 있는 것은 아니기 때문에, 플레이어는 잘 선택해야 합니다.슬롯 게임을 통해 이익을 얻기 위해 고려해야 할 요소는 다음과 같습니다:`,
        contentOne: [
            {
                head: `1. 게임의 RTP`,
                con: ` : 각 슬롯 머신마다 수익률이 다르기 때문에, 높은 RTP를 가진 게임을 선택하는 것이 유리합니다.`
            },
            {
                head: `2. 보너스와 프로모션`,
                con: ` : 무료 슬롯이나 보너스가 제공되는 게임을 활용하면, 초기 투자 없이도 게임을 즐길 수 있습니다.`,
            },
            {
                head: `3. 슬롯 커뮤니티`,
                con: ` : 다른 플레이어들과의 소통을 통해 어떤 게임이 더 이익을 주는지에 대한 인사이트를 얻을 수 있습니다.`,
            },
        ],
        tip: `팁: 무료 슬롯 게임을 경험해 보세요. 이를 통해 게임의 규칙과 보너스를 이해하고, 실제 돈을 투자하기 전에 전략을 세울 수 있습니다.`,
        contenttwo: `하지만 슬롯 게임은 단순한 운에 의존하기 때문에, 모든 플레이어가 이익을 얻을 수 있는 것은 아닙니다. 게임을 즐기면서도 손실을 최소화하는 방법에 대한 전략을 세우는 것이 중요합니다. 이를 통해 슬롯 게임의 재미를 극대화하고, 이익을 얻는 확률을 높일 수 있습니다. 다음 단계에서는 이러한 게임을 안전하게 즐기기 위한 방법에 대해 알아보겠습니다.`
    },
    {
        title: `- 온라인 카지노 사이트 플레이하는 것은 안전한가요?`,
        content: `온라인 카지노에서 슬롯 게임을 즐기는 사람이라면 "과연 이곳은 안전한가?"라는 질문을 한 번쯤은 고려해 보셨을 것입니다. 실제로 많은 분들이 온라인 슬롯사이트에서의 안전성에 의문을 품고 있습니다. 안전한 슬롯 사이트를 선택하기 위해서는 몇 가지 기준을 반드시 확인해야 합니다.`,
        contentOne: [
            {
                head: `1. 면허 및 인증`,
                con: ` : 신뢰할 수 있는 슬롯사이트는 공인된 면허를 보유하고 있습니다. 해당 사이트가 규정을 준수하고 있다는 것을 의미합니다.`
            },
            {
                head: `2. 보안 프로토콜`,
                con: ` : SSL 암호화를 사용하여 사용자 정보를 보호하는 사이트를 선택하면 안전성이 높아집니다.`,
            },
            {
                head: `3. 사용자 리뷰와 평판`,
                con: ` : 슬롯 커뮤니티의 리뷰를 살펴보면, 실제 사용자들의 경험을 바탕으로 사이트의 신뢰도를 평가할 수 있습니다.또한, 24시간 운영되는 고객 지원 서비스가 있는 사이트는 문제 발생 시 빠르게 대응할 수 있어 더욱 안심하고 플레이할 수 있습니다.`,
            },
        ],
        tip: `팁: 여러 슬롯사이트를 비교하면서 자신에게 맞는 곳을 선택하는 것이 중요합니다. 안전한 슬롯 사이트는 플레이어의 정보와 자금을 보호하는 데 중점을 두고 있습니다.`,
        contenttwo: `온라인 슬롯 게임의 세계는 재미와 흥분이 넘치지만, 플레이어의 안전과 보안이 뒷받침되어야 진정한 즐거움을 느낄 수 있습니다. 앞으로의 게임을 더욱 즐겁고 안전하게 만들어 줄 정보들이 기다리고 있습니다. 안전한 슬롯사이트에서의 경험이 여러분에게 행운을 가져다주기를 바랍니다.`
    },
    {
        title: `- 슬롯 커뮤니티 등록된 슬롯 사이트 업체들은 공평한가요?`,
        content: `슬롯 게임을 즐기시는 분들이라면, 등록된 슬롯 사이트 업체들이 정말 공평하게 운영되고 있는지 궁금해 하실 것입니다. 슬롯 커뮤니티 내에서의 평판은 사이트의 신뢰성을 평가하는 중요한 요소가 됩니다. 많은 사용자들이 자신이 선택한 슬롯사이트에 대해 불만을 제기할 경우, 이는 해당 사이트의 공정성에 대한 의문을 불러일으킬 수 있습니다.슬롯사이트의 공정성을 판단할 수 있는 몇 가지 기준을 제시합니다:`,
        contentOne: [
            {
                head: `1. RNG(난수 발생기)`,
                con: ` : 신뢰할 수 있는 슬롯사이트는 RNG 기술을 사용하여 게임 결과가 무작위로 결정되도록 보장합니다. 이는 모든 플레이어에게 공정한 게임 환경을 제공합니다.`
            },
            {
                head: `2. 정기적인 감사`,
                con: ` : 공인된 기관에서 정기적으로 슬롯사이트의 게임 결과를 감사하여, 공정성을 유지하고 있다는 증거를 제공합니다.`,
            },
            {
                head: `3. 투명한 규정`,
                con: ` : 사용자에게 명확한 이용 약관과 게임 규칙을 제공하는 사이트는 불공정한 운영을 피할 가능성이 높습니다.`,
            },
        ],
        tip: `팁: 슬롯 커뮤니티에서 다른 사용자들의 경험을 참고하여, 공정한 슬롯사이트를 선택할 수 있습니다.`,
        contenttwo: `슬롯 게임의 세계는 매력적이지만, 그 속에서 선수들의 권리가 보장되는 것이 무엇보다 중요합니다. 모든 플레이어가 공정한 게임을 즐길 수 있도록, 여러분의 선택이 더욱 신중해지길 바랍니다. 다음에 어떤 안전한 슬롯 사이트를 찾아야 하는지에 대한 정보가 기다리고 있으니, 지속적인 관심을 가져주시기 바랍니다.`
    },
    {
        title: `- 신뢰할 수 있는 슬롯 사이트 업체는 어떻게 찾나요?`,
        content: `온라인 슬롯 게임의 인기가 날로 높아짐에 따라, 신뢰할 수 있는 슬롯 사이트를 찾는 일이 더욱 중요해지고 있습니다. 많은 사용자들이 공정하고 안전한 게임 환경을 원하므로, 슬롯사이트 선택 시 몇 가지 기준을 고려하는 것이 필요합니다.`,
        contentOne: [
            {
                head: `1. 면허 여부`,
                con: ` : 신뢰할 수 있는 슬롯사이트는 공식 라이센스를 보유하고 있습니다. 이는 해당 사이트가 법적 규제를 준수하고 있다는 것을 의미합니다.`
            },
            {
                head: `2. 보안 시스템`,
                con: ` : SSL 암호화와 같은 보안 프로토콜을 통해 사용자 정보를 보호하는 사이트입니다. 이는 개인 정보의 안전을 보장하는 중요한 요소입니다.`,
            },
            {
                head: `3. 사용자 리뷰`,
                con: ` : 슬롯 커뮤니티에서의 사용자 리뷰는 신뢰성을 평가하는 데 큰 도움이 됩니다. 긍정적인 평가가 많은 사이트일수록 신뢰할 수 있습니다.`,
            },
        ],
        tip: `팁: 슬롯사이트를 선택할 때는 단순히 광고나 프로모션에 현혹되지 말고, 실제 사용자들의 경험을 참고하는 것이 좋습니다.`,
        contenttwo: `슬롯 게임을 통해 즐거움과 이익을 동시에 얻고자 하는 플레이어에게, 이러한 신뢰성 있는 슬롯사이트 선택은 필수입니다. 다음 단계에서는 다양한 온라인 슬롯 기기와 그들이 제공하는 게임들에 대해 알아볼 예정입니다. 이 정보를 통해 여러분의 게임 경험이 한층 더 풍부해지길 바랍니다.`
    },
    {
        title: `- 온라인 슬롯 사이트 기기들은 어떤걸 지원하나요?`,
        content: `온라인 슬롯 게임을 즐기시는 분들이라면 "과연 어떤 기기를 사용할 수 있을까?"라는 궁금증을 가지실 수 있습니다. 온라인 슬롯사이트는 다양한 기기를 지원하여 사용자들이 언제 어디서나 게임을 즐길 수 있도록 하고 있습니다.`,
        contentOne: [
            {
                head: `1. 모바일 기기`,
                con: ` : 스마트폰과 태블릿은 슬롯 게임을 플레이하기에 최적의 기기입니다. 대부분의 슬롯사이트는 HTML5 기반으로 제작되어, iOS와 안드로이드 기기에서 원활하게 작동합니다.`
            },
            {
                head: `2. 데스크톱 컴퓨터`,
                con: ` : 큰 화면에서 게임을 즐기고 싶다면 데스크톱 컴퓨터가 좋은 선택입니다. 고사양의 그래픽과 사운드를 경험할 수 있어 더욱 몰입감 있는 플레이가 가능합니다.`,
            },
            {
                head: `3. 게임 콘솔`,
                con: ` : 일부 슬롯사이트는 게임 콘솔을 통해서도 접근이 가능합니다. PS4, Xbox 등에서 슬롯 게임을 플레이할 수 있는 옵션이 제공되고 있습니다.`,
            },
        ],
        tip: `팁: 슬롯사이트에 접속하기 전, 어떤 기기를 통해 접근할 것인지 미리 정해두면 보다 스무스한 게임 환경을 경험할 수 있습니다.`,
        contenttwo: `온라인 슬롯사이트는 이러한 다양한 기기를 통해 사용자들에게 편리함과 즐거움을 제공하고 있습니다. 각 기기에서의 경험이 어떨지, 게임을 시작하기에 앞서 한번 더 고민해 보시기 바랍니다. 다음으로는 안전한 입금 방법에 대한 내용을 다루어 볼 예정입니다.`
    },
    {
        title: `- 온라인 카지노 사이트 입금 방법은 무엇인가요?`,
        content: `온라인 슬롯 게임의 세계는 매력적이지만, 많은 사용자들이 입금 방법에 대해 궁금해하실 것입니다. 사실, 온라인 카지노에서의 안전한 입금 방법은 플레이어의 게임 경험을 좌우하는 중요한 요소 중 하나입니다. 최근 조사에 따르면, 전 세계 온라인 카지노 사용자 중 72%가 안전한 입금 방식을 가장 중시한다고 응답했습니다. 이는 신뢰할 수 있는 슬롯사이트 선택에 있어 입금 방법의 중요성을 보여줍니다.온라인 슬롯사이트에서 지원하는 입금 방법은 다양합니다. 대표적으로는 다음과 같습니다:`,
        contentOne: [
            {
                head: `1. 신용카드/체크카드`,
                con: ` : 대부분의 슬롯사이트는 비자, 마스터카드와 같은 주요 카드 사용을 지원합니다. 즉각적인 입금이 가능해 많은 사용자들이 선호합니다.`
            },
            {
                head: `2. 전자지갑`,
                con: ` : 페이팔, 스크릴, 넷텔러와 같은 전자지갑은 빠르고 안전하게 거래할 수 있는 장점이 있습니다.`,
            },
            {
                head: `3. 은행 송금`,
                con: ` : 직접 은행 계좌를 통해 송금하는 방법으로, 큰 금액을 안전하게 이체할 수 있습니다.`,
            },
        ],
        tip: `팁: 입금 수수료와 처리 시간을 미리 확인하는 것이 좋습니다. 각 방법마다 차이가 있으니, 본인에게 가장 적합한 방법을 선택하세요.`,
        contenttwo: `이처럼 다양한 입금 방법을 통해 여러분은 보다 안전하고 편리한 게임 환경을 경험할 수 있습니다. 이러한 정보는 슬롯게임을 즐기는 데 있어 필수적이며, 다음 단계에서는 높은 수익을 기대할 수 있는 슬롯사이트에 대한 정보를 다룰 예정입니다. 안전한 게임 환경을 통해 여러분의 행운이 가득하길 바랍니다.`
    },
    {
        title: `- 큰 상금을 지불하는 온라인 슬롯 사이트 업체는 어디인가요?`,
        content: `온라인 슬롯 게임의 세계에서 큰 상금을 지불하는 사이트를 찾는 것은 많은 플레이어들에게 중요한 과제입니다. 실제로, 한 연구에 따르면, 플레이어의 68%가 높은 상금을 제공하는 슬롯사이트를 최우선으로 고려한다고 응답했습니다. 그렇다면, 어떤 온라인 슬롯 사이트가 그러한 큰 상금을 제공할까요?`,
        contentOne: [
            {
                head: `1. 인증된 슬롯사이트`,
                con: ` : 인증된 슬롯사이트들은 보통 높은 RTP(환급률)를 유지하고 있습니다. 예를 들어, 유명한 사이트들은 평균 95% 이상의 RTP를 제공하여 플레이어에게 공정한 게임 환경을 보장합니다.`
            },
            {
                head: `2. 보너스 프로그램`,
                con: ` : 큰 상금을 지급하는 슬롯사이트는 종종 매력적인 보너스 프로그램을 운영합니다. 무료 슬롯 게임이나 첫 입금 시 보너스가 제공되는 사이트들은 플레이어가 더 많은 기회를 가질 수 있도록 돕습니다.`,
            },
            {
                head: `3. 슬롯 커뮤니티의 평판`,
                con: ` : 사용자 리뷰와 커뮤니티의 의견은 해당 사이트의 신뢰성을 평가하는 데 중요한 요소입니다. 안정적이고 높은 상금을 지급하는 사이트는 대개 긍정적인 사용자 경험을 축적하고 있습니다.`,
            },
        ],
        tip: `팁: 슬롯사이트를 선택할 때는 항상 여러 사이트를 비교하고, 보너스 조건과 사용자 리뷰를 면밀히 확인하세요. 이를 통해 보다 안전하고 유리한 게임 환경을 확보할 수 있습니다.`,
        contenttwo: `온라인 슬롯사이트에서 큰 상금을 지불받는 경험은 꿈꾸는 순간이지만, 이를 위해서는 철저한 연구와 선택이 필요합니다. 다음 단계에서는 슬롯 커뮤니티에서 PG슬롯을 어떻게 활용할 수 있을지에 대한 정보를 제시할 예정입니다. 여러분의 게임 경험을 더욱 풍부하게 만들어줄 유용한 내용이 기다리고 있습니다.`
    },
    {
        title: `- 슬롯 커뮤니티 PG슬롯 이용방법은?`,
        content: `온라인 슬롯 게임을 즐기시는 분들이라면, 슬롯 커뮤니티에서 PG슬롯을 어떻게 활용할 수 있을지 고민해 보셨을 것입니다. 슬롯 커뮤니티는 다양한 정보와 경험을 공유할 수 있는 공간으로, 신뢰할 수 있는 슬롯사이트를 찾는 데 큰 도움이 됩니다. 사용자들이 제공하는 리뷰와 팁은 새로운 게임을 시도할 때 유용한 참고 자료가 됩니다. 예를 들어, 특정 슬롯 게임의 RTP나 보너스 시스템에 대한 정보를 공유하는 것은 다른 이용자들에게 큰 도움이 될 수 있습니다.슬롯 커뮤니티에서는 다음과 같은 방법으로 PG슬롯을 활용할 수 있습니다:`,
        contentOne: [
            {
                head: `1. 정보 공유`,
                con: ` : "PG슬롯"는 다양한 포럼을 제공합니다. 이곳에서는 각종 슬롯 게임 리뷰, 전략, 그리고 사용자 경험 등이 공유됩니다.`
            },
            {
                head: `2. 경험담 나누기`,
                con: ` : 실제로 해당 사이트에서의 경험을 공유함으로써, 다른 사용자들에게 유용한 인사이트를 제공합니다.`,
            },
            {
                head: `3. 보너스 및 프로모션 정보`,
                con: ` : 다양한 슬롯사이트에서 제공하는 보너스나 프로모션에 대한 정보를 실시간으로 확인할 수 있습니다.`,
            },
        ],
        tip: `팁: 슬롯 커뮤니티에 참여하면 최신 정보와 혜택을 놓치지 않고 얻을 수 있습니다. 적극적으로 소통하여 유익한 정보를 나누세요.`,
        contenttwo: `슬롯 커뮤니티는 여러분이 안전하고 즐거운 게임 경험을 쌓는 데 필수적인 자원입니다. 앞으로의 슬롯 게임 여정에서 유용한 인사이트를 얻을 수 있도록, 이러한 커뮤니티의 활동을 놓치지 않기를 바랍니다. 온라인 슬롯의 세계는 계속해서 발전하고 있으며, 여러분의 의견과 경험이 함께 더 나은 방향으로 나아갈 것입니다.`
    },

]

function HomeFAQ() {

    const [active, setActive] = useState([])

    const onActive = (index) => {
        let activeData = active;

        if(activeData.findIndex(item => item === index) > -1) {
            setActive(activeData.filter(item => item !== index));
            return;
        }
        setActive([...activeData, index]);
    }

    return (
        <div className="accordion-box">
            <div className="tit">
                <h2>PG슬롯 FAQ</h2>
                <p>PG슬롯와 관련된 모든 궁금증을 해결해드립니다! 자주 묻는 질문들을 통해 슬롯 게임의 기초부터 전략, 플레이 팁까지 모든 정보를 손쉽게 찾아보세요.
                    초보자부터 베테랑 플레이어까지, PG슬롯의 세계를 더 깊게 이해하실 수 있습니다.</p>
            </div>
            <ul className="list">

                {questionList.map((list, index) => {
                    return (
                        <li key={index}>
                            <div className="title" onClick={() => onActive(index)}>
                                <h2>{list.title}</h2>
                                <span className="arrow">
                                    <i className={`fa-solid ${(active.findIndex( val => index === val) > -1) ? "fa-angle-down" : "fa-angle-up"}`} aria-hidden="true"></i>
                                </span>
                            </div>
                            <div className="con" style={{ display: (active.findIndex( val => index === val) > -1) ? "block" : "none" }}>
                                <p>{list.content}</p>

                                {!isEmpty(list.contentOne) &&
                                    <dl>
                                        {list.contentOne.map((item, index) => {
                                            return (
                                                <dt key={index}>
                                                    <h3>{item.head}</h3>
                                                    <p>{item.con}</p>
                                                </dt>
                                            )
                                        })}
                                    </dl>
                                }
                                <p style={{ color: "#c6763a" }}>{list.tip}</p>
                                <p>{list.contenttwo}</p>

                            </div>
                        </li>
                    )
                })
                }
            </ul>

        </div>
    );
}