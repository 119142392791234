import React, {useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {RightSideBar, SideBar} from '../Layout';
import {Link} from "react-router-dom";
import {eventActions, siteActions} from '../../store';
import {resource_url} from '../../Utils/keys';
import {dateFormation, isEmpty} from '../../Utils';
import {LiveEventDetail} from './components';

export {LiveEventContent};

function LiveEventContent() {

    const dispatch = useDispatch();
    const liveEventList = useSelector(state => state.event.liveEventList);
    const mainBanner = useSelector(state => state.site.mainBanner)

    const [showDetail,
        setShowDetail] = useState(false);

    const [active,
        setActive] = useState(0);

    const onShowDetail = (index) => {
        setShowDetail(true)
        setActive(index)
    }

    const onBtnClick = () => {
        setShowDetail(!showDetail)
        setActive(-1)
    }

    const getList = () => {
        dispatch(eventActions.getLiveEventList());
        dispatch(siteActions.getMainBannerUrl());
    }
    useEffect(() => {
        getList();
    }, []);

    const videoUrl = resource_url + "/" + mainBanner.url
    const youtubeUrl = mainBanner.url

    return (
        <div className="container">
            <div className="row" style={{
                margin: "0"
            }}>
                {showDetail && <LiveEventDetail
                    onBtnClick={onBtnClick}
                    liveEventList={liveEventList[active]}
                    setActive={setActive}
                    active={active}
                    length={liveEventList.length}/>
}

                {!showDetail && <div className="main_area">
                    <div className="event_page_banner">
                        <video
                            src={mainBanner.status === 0 && (mainBanner.type === 2
                            ? videoUrl
                            : youtubeUrl)}
                            muted
                            autoPlay={true}
                            loop={true}
                            style={{
                            background: "#191b22"
                        }}></video>
                    </div>
                    <div className="page_seo_tit_box">
                        <h2>PG슬롯 이벤트</h2>
                        <p>NO.1 슬롯 커뮤니티 PG슬롯에서 진행하는 이벤트는 PG슬롯 회원이면 누구나 참여가능합니다. 이벤트 참여하시기 전
                            <Link to="/notice" title="이벤트 규정사항 바로가기">이벤트 공통 규정사항</Link>
                            숙지후 이용해주시기 바랍니다.</p>
                        <div className="event_write_btn">
                            <Link
                                to="/dieevent"
                                title="종료된 이벤트 바로가기"
                                style={{
                                background: "rgb(8 42 71)"
                            }}>종료된 이벤트 바로가기</Link>
                        </div>
                    </div>
                    <div className="event_board">
                        <form
                            action="https://slotbuff1.com/board/event"
                            name="fboardlist"
                            id="fboardlist"
                            method="post"
                            acceptCharset="utf-8">
                            <div className="gallery_list full_gallery_list event_list">
                                <ul
                                    style={{
                                    display: "flex",
                                    flexWrap: "wrap"
                                }}>
                                    {liveEventList && liveEventList.map((list, index) => {
                                        return (
                                            <li key={index}>
                                                <div className="box">
                                                    <div
                                                        className="imgs cursor-pointer"
                                                        title={list.title}
                                                        onClick={() => onShowDetail(index)}>
                                                        <img src={`${resource_url}/${list.image}`} alt={list.title}/></div>
                                                    <div className="txt">
                                                        <h3>{list.title}</h3>
                                                        <span className="date">
                                                            이벤트 기간 : {dateFormation(list.start_date, 3)}
                                                            ~ {dateFormation(list.end_date, 3)}
                                                        </span>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })
}
                                    {isEmpty(liveEventList) && <li>
                                        <div className="box">
                                            <div className="txt">
                                                <h3>내역이 없습니다.</h3>
                                            </div>
                                        </div>
                                    </li>
}

                                </ul>
                            </div>
                        </form>
                        <div className="border_button"></div>
                    </div>
                </div>
}
                <SideBar/>
            </div>
        </div>
    );
}