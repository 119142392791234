import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { RightSideBar, SideBar } from '../Layout';
import { eventActions } from '../../store';
import { resource_url } from '../../Utils/keys';
import { DieEventDetail } from './components';
import { isEmpty } from '../../Utils';




export { DieEventContent };

function DieEventContent() {


    const dispatch = useDispatch();
    const dieEventList = useSelector(state => state.event.dieEventList);

    const [showDetail, setShowDetail] = useState(false);

    const [active, setActive] = useState(0)

    const onShowDetail = (index) => {
        setShowDetail(true)
        setActive(index)
    }

    const onBtnClick = () => {
        setShowDetail(!showDetail)
        setActive(-1)
    }

    const getList = () => {
        dispatch(eventActions.getDieEventList());
    }
    useEffect(() => {
        getList();
    }, []);

    return (
        <div className="container">
            <div className="row" style={{ margin: "0" }}>
                {showDetail &&
                    <DieEventDetail
                        onBtnClick={onBtnClick}
                        dieEventList={dieEventList[active]}
                        setActive={setActive}
                        active={active}
                        length={dieEventList.length}
                    />
                }
                {!showDetail &&
                    <div className="main_area">
                        <div className="page_seo_tit_box">
                            <h2>종료된 이벤트</h2>
                            <p>PG슬롯에서 진행된 이벤트 목록입니다. 현재는 종료된 이벤트이므로 참여할 수 없습니다.</p>
                            <div className="event_write_btn">
                                <Link to="/liveevent" title="진행중 이벤트 바로가기" style={{ background: "rgb(8 42 71)" }}>진행중 이벤트 바로가기</Link>
                            </div>
                        </div>
                        <div className="event_board">
                            <form action="https://slotbuff1.com/board/end_event" name="fboardlist" id="fboardlist" method="post" acceptCharset="utf-8">
                                <div className="gallery_list full_gallery_list event_list end_event_list">
                                    <ul style={{ display: "flex", flexWrap: "wrap" }}>
                                        {dieEventList &&
                                            dieEventList.map((list, index) => {
                                                return (
                                                    <li key={index}>
                                                        <div className="box">
                                                            <div className="imgs cursor-pointer" title={list.title} onClick={() => onShowDetail(index)}>
                                                                <img src={`${resource_url}/${list.image}`} alt={list.title} />
                                                            </div>
                                                            <div className="txt">
                                                                <h3>{list.title}</h3>
                                                                <span className="date">종료된 이벤트입니다</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            })

                                        }
                                        {isEmpty(dieEventList) &&
                                            <li>
                                                <div className="box">
                                                    <div className="txt">
                                                        <h3>내역이 없습니다</h3>
                                                    </div>
                                                </div>
                                            </li>
                                        }

                                    </ul>
                                </div>
                            </form>
                            <div className="border_button">
                            </div>
                        </div>
                    </div>
                }
                <SideBar />
            </div>
        </div>
    );

}