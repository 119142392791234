import React, { useState, useEffect } from 'react';
import { MiniGamePanel, MiniGameVideo, MiniBettingHist } from './components';
import { getMinigameUrl } from '../../Utils';

export { SoccerContent };


function SoccerContent() {

    const [url, setUrl] = useState("");

    useEffect(() => {
        getMinigameUrl("soccer").then(url => setUrl(url)).catch(err => {
            console.error('err: ', err);
        })
    }, [])
    return (
        <div className="main_area">
            <MiniGameVideo type="soccer" url={url}/>
            <MiniGamePanel type="soccer" />
            <MiniBettingHist type="soccer" />
        </div>
    );
}
