import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { siteActions } from '../../store';
import { resource_url } from '../../Utils/keys';
import { VerifiedDetail } from './components';
import { changeEditorValue, isEmpty } from '../../Utils';
import { RightSideBar, SideBar } from '../Layout';

export { VerifiedSiteContent };

function VerifiedSiteContent() {

    const dispatch = useDispatch();
    const verifiedList = useSelector(state => state.site.verifiedList);

    const verifiedSiteRef = useRef();


    const [showDetail, setShowDetail] = useState(false);

    const [active, setActive] = useState(0)

    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });

    const onShowDetail = (index) => {
        setShowDetail(true)
        setActive(index)
        getAnswerList(1, 10, index)
    }

    const onBtnClick = () => {
        setShowDetail(!showDetail)
        setActive(-1)
    }

    const getAnswerList = (page = 1, perPage = 10, index = active) => {
        setPageInfo({
            ...pageInfo,
            curPage: page,
            perPage: Number(perPage)
        });
        const params = {
            type: "VerifySites",
            typeId: verifiedList[index].id,
            page: page,
            perPage: perPage,
        }
        dispatch(siteActions.getAnswerList(params));

    }

    const getList = () => {
        dispatch(siteActions.getVerifiedList());
    }
    useEffect(() => {
        getList();
    }, []);

    return (
        <div className="container">
            <div className="row" style={{ margin: "0" }}>
                <div className='main_area'>
                    {showDetail &&
                        <VerifiedDetail
                            onBtnClick={onBtnClick}
                            getList={getAnswerList}
                            verifiedList={verifiedList[active]}
                            pageInfo={pageInfo}
                            setPageInfo={setPageInfo}
                            setActive={setActive}
                            active={active}
                            length={verifiedList.length}
                        />
                    }
                    {!showDetail &&
                        <>
                            <div className="page_seo_tit_box">
                                <h2>온라인 슬롯 사이트 제휴 업체</h2>
                                <p>PG슬롯은 온라인 슬롯 사이트 보증 및 슬롯 커뮤니티 공간으로, 사용자에게 최상의 경험을 제공하기 위해 다양한 제휴 업체와 협력하고 있습니다.
                                    이러한 제휴 업체들은 PG슬롯의 엄격한 평가 기준을 통과한, 신뢰성 있고 안정적인 슬롯 게임 제공자들입니다.
                                    PG슬롯의 "보증" 마크는 이런 제휴 업체들과 함께, 사용자들에게 안전한 게임 환경과 품질을 확신시켜주는 상징입니다.
                                    사용자들은 PG슬롯에서 추천하는 제휴 업체들의 슬롯 게임을 믿고 플레이 할 수 있으며, 최상의 온라인 슬롯 게임 경험을 기대할 수 있습니다.</p>
                            </div>
                            <div className="board">

                                <div className='board-margin'>


                                    <div className="new_warranty">
                                        <ul>
                                            {verifiedList &&
                                                verifiedList.map((list, index) => {
                                                    return (
                                                        <li className="list" key={index}>
                                                            <div className="box">
                                                                <div className="top_area"><span>{list.siteName} 카지노 &amp; 슬롯사이트</span>{list.comment}</div>
                                                                <div className="conts_area">
                                                                    <div className="thum_img box cursor-pointer" title="레이브 카지노 &amp; 슬롯사이트" onClick={() => onShowDetail(index)}>
                                                                        <img src={`${resource_url}/${list.images.titleImg}`} alt="레이브 카지노 &amp; 슬롯사이트" className="imgs" />
                                                                    </div>
                                                                    <div className="box">
                                                                        <div className="tit"><img src="../imgs/warranty/icon_event.png" alt="이벤트" /></div>
                                                                        <dl dangerouslySetInnerHTML={{ "__html": !isEmpty(list.detail.event) ? changeEditorValue(list.detail.event) : "" }}></dl>
                                                                    </div>
                                                                    <div className="box">
                                                                        <div className="tit"><img src="../imgs/warranty/icon_joinevent.png" alt="가입이벤트" /></div>
                                                                        <dl dangerouslySetInnerHTML={{ "__html": !isEmpty(list.detail.join_event) ? changeEditorValue(list.detail.join_event) : "" }}></dl>
                                                                    </div>
                                                                    <div className="box infomation_box">
                                                                        <div className="tit"><img src="../imgs/warranty/icon_info.png" alt="인포메이션" /></div>
                                                                        <dl dangerouslySetInnerHTML={{ "__html": !isEmpty(list.detail.information) ? changeEditorValue(list.detail.information) : "" }}></dl>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </li>
                                                    )
                                                })

                                            }

                                            {isEmpty(verifiedList) &&
                                                <li>
                                                    <div className="box">
                                                        <div className="txt">
                                                            <span>내역이 없습니다.</span>
                                                        </div>

                                                    </div>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <nav></nav>
                            </div>
                            <div className="page_seo_tit_box">
                                <h2>온라인 슬롯 사이트 선정 기준 - 슬롯 커뮤니티 보증</h2>
                                <ul>
                                    <li>1. 카지노 게임의 품질: 슬롯 커뮤니티는 먼저 게임의 품질을 검토합니다. 높은 그래픽 품질, 부드러운 게임 플레이, 그리고 사용자 친화적인 인터페이스가 중요한 평가 기준이 됩니다.</li>
                                    <li>2. 사이트의 안정성: 온라인 슬롯사이트의 서버 안정성, 사이트의 보안, 그리고 트래픽 처리 능력을 평가합니다. 사용자들이 원활하게 게임을 즐길 수 있어야 합니다.</li>
                                    <li>3. 고객 서비스: 고객 서비스의 반응 속도, 문제 해결 능력, 그리고 서비스 제공 시간 등을 검토합니다. 사용자의 문제나 요구사항에 신속하고 정확하게 대응하는 것이 중요합니다.</li>
                                    <li>4. 보너스 및 프로모션: 제공되는 보너스의 종류, 금액, 그리고 사용 조건 등을 검토합니다. 사용자에게 가치 있는 보너스와 프로모션을 제공하는지 평가합니다.</li>
                                    <li>5. 사용자의 피드백: 실제 사용자들의 피드백과 리뷰도 중요한 선정 기준입니다. 사용자들의 직접적인 경험과 만족도를 통해 사이트의 실질적인 품질을 확인합니다.</li>
                                    <li>6. 슬롯 커뮤니티의 보증: 모든 기준을 충족한 사이트만이 슬롯 커뮤니티의 보증을 받게 됩니다. 이 보증은 사용자들에게 해당 사이트가 안전하고 신뢰할 수 있다는 것을 알려주는 중요한 지표가 됩니다.</li>
                                </ul>
                                <p>이러한 선정 기준을 통해 슬롯 커뮤니티는 사용자들에게 최고의 온라인 슬롯사이트를 안내하고, 그들의 게임 경험을 향상시키고자 합니다.</p>
                            </div>

                            <div className="page_seo_conts_box">
                                <h2>온라인 카지노 슬롯 사이트 이용 안내 Q&amp;A</h2>
                                <div className="section_box">
                                    <h3>1. 슬롯 사이트 회원가입 절차는 다음과 같습니다</h3>
                                    <ul>
                                        <li>- 원하시는 웹사이트 혹은 모바일 앱의 상단에서 '회원가입' 버튼을 선택하세요.</li>
                                        <li>- 필요한 정보인 이름, 휴대폰 번호, 계좌 정보, 가입 코드 등을 입력해 주십시오.</li>
                                        <li>- 회원가입을 마치기 전에, 가상계좌 승인을 위한 인증 절차를 받을 수 있습니다.</li>
                                    </ul>
                                    <p>참고로, 18세 미만은 가입이 제한되며, 서비스 이용 전에 반드시 이용약관을 확인하시기 바랍니다.</p>
                                </div>

                                <div className="section_box">
                                    <h3>2. 카지노 사이트 신규로 가입했는데 접근이 차단되었다면?</h3>
                                    <p>신규 회원이 접근을 차단받는 경우가 있으며, 주된 원인은 보안 문제 때문입니다.
                                        슬롯사이트마다 가입 승인 조건이나 기준이 다를 수 있으며, 사용자의 안전을 위해 엄격한 기준을 적용하기도 합니다. 그렇기에 보안 및 가입 조건은 꼼꼼하게 관리됩니다.
                                        추가적으로, 로얄클럽과 토라이 같은 회원 검증 플랫폼에서 블랙리스트 조회를 통해 가입 정보를 확인하므로, 그 결과로 인해 회원 가입이 제한될 수 있습니다.</p>
                                </div>

                                <div className="section_box">
                                    <h3>3. 온라인 카지노 게임 베팅 없이 돈을 인출할 수 있을까요?</h3>
                                    <p>대부분의 카지노 사이트에서는 자금을 입금하고 베팅을 하지 않으면 출금 처리가 불가능합니다. 이러한 규정은 주로 자금 세탁을 예방하기 위한 조치입니다. 따라서, 정상적인 출금 절차를 위해선 사용자가 입금한 금액은 카지노사이트의 규정사항을 꼭 읽어 보시기 바랍니다.</p>
                                </div>

                                <div className="section_box">
                                    <h3>4. 카지노 사이트 인출할 수 있는 최고 금액은 어느 정도인가요?</h3>
                                    <p>PG슬롯 제휴 업체 카지노 사이트에서는 출금한도에 특별한 제한을 두지 않습니다. 하지만, 각 사이트마다 그 세부적인 규정과 방침이 다를 수 있기 때문에 출금을 원하시는 경우, 해당 사이트의 이용약관이나 출금 관련 규정을 자세히 읽어보시는 것이 좋습니다. 특히, 간혹 일정 금액 이상의 큰 금액을 출금하고자 할 때 추가적인 검증 절차가 필요할 수도 있으므로, 사전에 출금 규정을 철저히 파악하고 이용하시는 것을 추천드립니다.</p>
                                </div>

                                <div className="section_box">
                                    <h3>5. PG슬롯 보증하는 슬롯 사이트는 신뢰할 수 있나요?</h3>
                                    <p>PG슬롯가 추천하는 슬롯사이트는 확실한 안전성을 자랑합니다. 단순 추천 후 관리를 소홀히 하는 것이 아닌, 계속적인 모니터링을 통해 사용자의 안전을 최우선으로 생각합니다.
                                        게임 이용 중나 환전 과정에서 문제가 발생할 수 있는 상황을 미리 방지하기 위해 지속적인 체크를 하고 있습니다.
                                        저희의 엄격한 품질 관리 절차와 검증 시스템을 거친 슬롯사이트만을 선별하여 추천하므로, 어떠한 문제 상황에도 즉각 대응하며, 100% 보상을 원칙으로 하여 사용자의 안전을 보장합니다.
                                    </p>
                                </div>

                                <div className="section_box">
                                    <h3>6. 카지노 사이트 개인정보는 안전하게 보관 되나요?</h3>
                                    <ul>
                                        <li>- 암호화 기술: 대부분의 카지노 사이트는 128Bit 혹은 그 이상의 암호화 기술을 사용하여 회원의 개인정보와 금융정보를 안전하게 보호합니다. 이는 외부의 해킹 시도나 불법 접근으로부터 회원 정보를 안전하게 지키는 첫 단계입니다.</li>
                                        <li>- 개인정보 처리방침: 카지노 사이트는 회원의 개인정보 처리에 대한 정책을 명시하고, 이를 준수합니다. 이는 어떤 정보를 수집하고, 어떻게 그 정보를 사용하며, 어떻게 보호하는지에 대한 내용을 포함합니다.</li>
                                        <li>- 제3자 정보 제공 제한: 카지노 사이트는 회원의 개인정보를 제3자에게 임의로 제공하거나 판매하지 않습니다. 정보를 공유하는 경우에도 그 사유와 목적이 명확히 설명되고, 회원의 동의를 받아야 합니다.</li>
                                        <li>- 지속적인 모니터링: 카지노 사이트는 외부의 위협으로부터 회원 정보를 보호하기 위해 지속적인 모니터링과 업데이트를 실시합니다.</li>
                                        <li>- 데이터 보관 및 삭제: 카지노 사이트는 회원의 개인정보를 필요한 기간 동안만 보관하며, 그 후 안전하게 삭제하거나 파기합니다. 회원 탈퇴나 목적 달성 후에는 즉시 해당 정보를 파기합니다.</li>
                                        <li>- 개인정보 보호 교육: 직원들에게 개인정보 보호에 대한 교육을 주기적으로 실시하여, 실수나 과실로 인한 정보 유출을 방지합니다.</li>
                                    </ul>
                                    <p>개인정보의 보호를 위해 대다수의 카지노 사이트들이 이러한 기준을 준수하려 노력하고 있습니다. 하지만 사용자 스스로도 개인정보 보호에 관한 사이트의 정책과 안전 조치를 확인하는 것이 중요합니다.</p>
                                </div>
                            </div>

                            <div className="page_seo_tit_box">
                                <h2>온라인 슬롯 커뮤니티 - PG슬롯</h2>
                                <p>PG슬롯은 온라인 슬롯 커뮤니티 업계에서 대표적인 보증 업체입니다.
                                    우리는 사용자들이 안심하고 게임을 즐길 수 있도록 최고의 슬롯 사이트만을 엄선해서 추천합니다.
                                    또한, PG슬롯와 함께하는 모든 제휴 사이트는 우리의 엄격한 평가를 거쳤기 때문에 안정성과 서비스 품질이 보장됩니다.
                                    오랜 시간 동안 쌓아온 전문성과 노하우를 바탕으로, 플레이어분들께 최상의 경험을 제공하고자 항상 노력하고 있습니다.
                                    따라서 PG슬롯를 통해 추천받은 슬롯 사이트에서는 항상 만족스러운 게임 환경을 기대하실 수 있습니다.</p>
                            </div>
                        </>}
                </div>
                <SideBar />
            </div>
        </div>
    );
}