import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useCallback, useMemo } from "react";
import { Pagination } from '../Common';
import { RightSideBar, SideBar } from '../Layout';


import QuillEditor from "react-quill";

import "react-quill/dist/quill.snow.css";
import { eventActions, siteActions } from '../../store';
import { InquireDetail } from './components';
import { dateFormation, isEmpty, toastr } from '../../Utils';

export { InquireContent };

function InquireContent() {

    const dispatch = useDispatch();
    const inquireList = useSelector(state => state.event.inquireList);
    const userData = useSelector(state => state.user.data);
    const isAuthenticated = useSelector(state => state.user.isAuthenticated);

    const [showDetail, setShowDetail] = useState(false);

    const [active, setActive] = useState(0);

    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });

    const [searchInputs, setSearchInputs] = useState({
        searchType: "",
    });

    const { searchType } = searchInputs;

    const onChange = (e) => {
        const { name, value } = e.target;
        setSearchInputs({
            ...searchInputs,
            [name]: value
        })
        if (name === "searchType") {
            const param = {
                searchType: value,
            }
            getList(1, pageInfo.perPage, param);
        }
    }

    const onShowDetail = (index) => {
        setShowDetail(true)
        setActive(index)
    }

    const onBtnClick = () => {
        setShowDetail(!showDetail)
        setActive(-1)
    }

    const getList = (page = 1, perPage = 10, search = searchInputs) => {
        setPageInfo({
            ...pageInfo,
            curPage: page,
            perPage: Number(perPage)
        })
        setSearchInputs(search)
        const params = {
            page: page,
            perPage: perPage,
            type: search.searchType,
        }
        dispatch(eventActions.getInquireList(params));
    }
    useEffect(() => {
        getList();
    }, []);




    const [inputs, setInputs] = useState({
        question: "",
        title: "",
    });
    const { question, title } = inputs;
    const [showDoquetion, setShowQuestion] = useState(false);

    const onShowQuestion = () => {
        if (!isAuthenticated) {
            toastr.warning("로그인하고 이용해주세요.");
        } else {
            setShowQuestion(!showDoquetion);
        }
    }

    const onSendInquire = (e) => {
        e.preventDefault();
        if(isEmpty(title)) {
            return toastr.warning("제목을 입력해주세요.")
        }
        if(isEmpty(question)) {
            return toastr.warning("내용을 입력해주세요.")
        }
        const sendParams = {
            id: userData.id,
            ...inputs,
        }
        dispatch(eventActions.sendInquire(sendParams));
        setInputs({
            title: "",
            question: "",
        })
        setShowQuestion(!showDoquetion);
    }

    const quill = useRef();

    const imageHandler = useCallback(() => {
        // Create an input element of type 'file'
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = () => {
            const file = input.files[0];
            const reader = new FileReader();

            reader.onload = () => {
                const imageUrl = reader.result;
                const quillEditor = quill.current.getEditor();

                const range = quillEditor.getSelection(true);
                quillEditor.insertEmbed(range.index, "image", imageUrl, "user");
            };

            reader.readAsDataURL(file);
        };
    }, []);

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ header: [2, 3, 4, false] }],
                    ["bold", "italic", "underline", "blockquote"],
                    [{ color: [] }],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                    ],
                    ["link", "image"],
                    ["clean"],
                ],
                handlers: {
                    image: imageHandler,
                },
            },
            clipboard: {
                matchVisual: true,
            },
        }),
        [imageHandler]
    );

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "color",
        "clean",
    ];



    return (
        <div className="container">
            <div className="row" style={{ margin: "0" }}>
                {showDetail && <InquireDetail
                    length={inquireList.length}
                    active={active}
                    onBtnClick={onBtnClick}
                    setActive={setActive}
                    inquireList={inquireList[active]}
                />
                }
                {
                    showDoquetion &&
                    <div className="main_area">
                        <div className="board">
                            <h3>고객센터 글쓰기</h3>
                            <div className="form-horizontal" name="fwrite" id="fwrite"
                                encType="multipart/form-data" method="post" acceptCharset="utf-8"
                                noValidate="novalidate">


                                <div className="form-horizontal box-table">
                                    <div className="form-group">
                                        <div className="col-sm-12" style={{ display: "flex" }}>
                                            <label htmlFor="title" className="control-label">제목</label>
                                            <div className="" style={{ display: "table", width: "100%", marginLeft: "15px" }}>
                                                <input type="text" className="form-control"
                                                    name="title" id="title" value={title}
                                                    onChange={(e) => { setInputs({ ...inputs, title: e.target.value }) }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-sm-12">
                                            <QuillEditor
                                                ref={(el) => (quill.current = el)}
                                                className={"form-control"}
                                                style={{ minHeight: "300px", height: "auto", padding: "0px" }}
                                                theme="snow"
                                                value={question}
                                                formats={formats}
                                                modules={modules}
                                                onChange={(value) => { setInputs({ ...inputs, question: value }) }}
                                            />
                                        </div>
                                    </div>

                                    <div className="border_button text-center mt20">
                                        <button type="button" className="btn btn-primary btn-sm btn-history-back mr10" onClick={onShowQuestion}>취소</button>
                                        <button type="submit" className="btn btn-success btn-sm" onClick={onSendInquire}>작성완료</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                {(!showDoquetion && !showDetail) &&
                    <div className="main_area">
                        <div className="video_banner">
                            <img src="../imgs/benner_cs.png" alt="고객센터 궁금하신 사항이 있으시면 언제든지 문의글을 남겨주시면 성심성의껏 답변드리겠습니다." />
                        </div>
                        <div className="board">
                            <div className="board_form_box">
                                { isAuthenticated &&
                                    <div className="pull-right">
                                    <a className="notice_btn" onClick={onShowQuestion}>1:1 문의하기</a>
                                </div>
                                }
                            </div>
                            <div style={{overflowX: "auto"}}>
                                <table className="table table-hover table-bordered normal_board">
                                    <thead>
                                        <tr>
                                            <th> 번호 </th>
                                            <th>제목</th>
                                            <th>날짜</th>
                                            <th style={{ whiteSpace: "nowrap" }}>문의결과</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {inquireList &&
                                            inquireList.map((list, index) => {
                                                return (
                                                    <tr key={index} onClick={() => onShowDetail(index)}>
                                                        <td>{Number(pageInfo.perPage) * (Number(pageInfo.curPage) - 1) + index + 1}</td>
                                                        <td>
                                                            <span title={list.title} style={{ marginLeft: "14px" }}>{list.title}</span>
                                                            <span className="new_icon">N</span>
                                                        </td>
                                                        <td style={{whiteSpace: "nowrap"}}>{dateFormation(list.created_date, 4)}</td>
                                                        <td>
                                                            <span className={list.status === 2 ? "text-success" : "text-danger"}>
                                                                {list.status === 2 ? "답변완료" : "미답변"}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )
                                            })

                                        }
                                    </tbody>
                                    {
                                        isEmpty(inquireList) &&
                                        <tbody>
                                            <tr>
                                                <td colSpan="4" className="nopost">내역이 없습니다</td>
                                            </tr>
                                        </tbody>
                                    }
                                </table>
                            </div>
                            {!isEmpty(inquireList) &&
                                <Pagination pageInfo={pageInfo} getList={getList} />
                            }
                        </div>
                    </div>

                }
                <SideBar />
            </div>
        </div>
    );

}