import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { RightSideBar, SideBar } from '../Layout';
import { Pagination } from '../Common';
import { communityActions, siteActions } from '../../store';
import { FreeArticleDetail } from './components';
import { api_url, resource_url } from '../../Utils/keys';
import { Edit } from '../Common';
import { dateFormation, isEmpty, toastr } from '../../Utils';
import { request } from '../../Utils/api';

export { FreeArticleContent };

function FreeArticleContent() {

    const dispatch = useDispatch();
    const freeArticleList = useSelector(state => state.community.freeArticleList);
    const isAuthenticated = useSelector(state => state.user.isAuthenticated);

    const [showDetail, setShowDetail] = useState(false);
    const [active, setActive] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const [pageInfo, setPageInfo] = useState({ curPage: 1, totalPage: 1, perPage: 10 });

    const [searchInputs, setSearchInputs] = useState({
        searchType: "",
        searchText: "",
    });

    const { searchType, searchText } = searchInputs;

    const onChange = (e) => {
        const { name, value } = e.target;
        setSearchInputs({
            ...searchInputs,
            [name]: value
        })
        if (name === "searchType") {
            const param = {
                searchType: value,
                searchText: searchText,
            }
            getList(1, pageInfo.perPage, param);
        }
    }


    const onShowModal = () => {
        if (!isAuthenticated) {
            toastr.warning("로그인하고 이용해주세요.")
        } else {
            setShowModal(!showModal)
        }
    }

    const onEditComplete = (params) => {
        const sendParams = {
            titleImg: params.titleImg,
            articleList: params.articleList,
            title: params.title,
        }
        dispatch(communityActions.sendFreeArticle(sendParams));
        onShowModal();
    }
    const onShowDetail = (index) => {
        setShowDetail(true)
        setActive(index)
        getAnswerList(1, 10, index)
        const params = {
            type: "freeArticleList",
            typeId: freeArticleList[index].id,
        }
        request.post(api_url + "/api/Article/viewcount", params)
    }
    const getAnswerList = (page = 1, perPage = 10, index = active) => {
        setPageInfo({
            ...pageInfo,
            curPage: page,
            perPage: Number(perPage)
        });
        const params = {
            type: "freeArticleList",
            typeId: freeArticleList[index].id,
            page: page,
            perPage: perPage,
        }
        dispatch(siteActions.getAnswerList(params));

    }

    const onBtnClick = () => {
        setShowDetail(!showDetail)
        setActive(-1)
    }

    const onSearch = () => {
        const param = {
            ...searchInputs
        }
        getList(1, pageInfo.perPage, param);
    }
    const onEnter = (e) => {
        if (e.key === "Enter") {
            onSearch();
        }
    }

    const getList = (page = 1, perPage = 10, search = searchInputs) => {
        setPageInfo({
            ...pageInfo,
            curPage: page,
            perPage: Number(perPage)
        });
        setSearchInputs(search)
        const params = {
            page: page,
            perPage: perPage,
            type: search.searchType,
            key: search.searchText,
        }
        dispatch(communityActions.getFreeArticleList(params));
    }

    useEffect(() => {
        getList();
    }, []);

    return (
        <div className="container">
            <div className="row" style={{
                margin: "0"
            }}>
                {showModal && <Edit
                    type="freeArticle"
                    titleType="자유게시판 글쓰기"
                    showModal={showModal}
                    onShowModal={onShowModal}
                    onEditComplete={onEditComplete} />
                }
                {showDetail && <FreeArticleDetail
                    length={freeArticleList.length}
                    active={active}
                    onBtnClick={onBtnClick}
                    setActive={setActive}
                    freeArticleList={freeArticleList[active]}
                    pageInfo={pageInfo}
                    getList={getAnswerList} />
                }
                {(!showModal && !showDetail) && <div className="main_area">
                    <div className="board">
                        <div className="board_form_box">
                            <div className="array_area">
                                <select name="searchType" value={searchType} onChange={onChange} >
                                    <option value="">전체</option>
                                    <option value="date">날짜순</option>
                                    <option value="viewCnt">조회수</option>
                                </select>
                            </div>
                            <div className="search_area">
                                <div className="searchbox">
                                    <div>
                                        <span><input type="text" placeholder="검색" name="searchText" value={searchText} onChange={onChange} onKeyDown={onEnter}/></span>
                                        <span><button onClick={onSearch}><i className="fa fa-search" aria-hidden="true"></i></button></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form
                            action="https://slotbuff1.com/board/freeboard"
                            name="fboardlist"
                            id="fboardlist"
                            method="post"
                            acceptCharset="utf-8">
                            <table className="table table-hover normal_board freeboard table-bordered">
                                <thead>
                                    <tr>
                                        <th style={{width: "10%"}}>번호</th>
                                        <th style={{width: "60%"}}>제목</th>
                                        <th style={{width: "10%"}}>글쓴이</th>
                                        <th style={{width: "10%"}}>날짜</th>
                                        <th style={{width: "10%"}}>조회</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {freeArticleList && freeArticleList.map((list, index) => {
                                        return (
                                            <tr key={index} onClick={() => onShowDetail(index)}>
                                                <td>{Number(pageInfo.perPage) * (Number(pageInfo.curPage) - 1) + index + 1}</td>
                                                <td className="center">
                                                    <span title={list.title}>{list.title}</span>
                                                    <span className="new_icon">N</span>
                                                    <span className="imgs">
                                                        <img src={list.titleImg} alt={list.title} />
                                                    </span>
                                                </td>
                                                <td>
                                                    <div className="user_levelname">
                                                        <span className="lv_icon_box">
                                                            <div className={`lv_icon lv_icon${list.level > 6 ? 1 : list.level}`}></div>
                                                        </span>
                                                        <span className="user_name">{list.loginId}</span>
                                                    </div>
                                                </td>
                                                <td>{dateFormation(list.created_date, 5)}</td>
                                                <td>{list.viewCnt}</td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                                {
                                    isEmpty(freeArticleList) &&
                                    <tbody>
                                        <tr>
                                            <td colSpan="5" className="nopost">내역이 없습니다</td>
                                        </tr>
                                    </tbody>
                                }
                            </table>
                        </form>
                        <div className="border_button">
                            {isAuthenticated &&
                                <div className="pull-right">
                                    <button className="btn btn-success btn-sm" onClick={onShowModal}>글쓰기</button>
                                </div>}
                        </div>
                        {!isEmpty(freeArticleList) &&
                            <Pagination pageInfo={pageInfo} getList={getList} />
                        }
                    </div>

                </div>}
                <SideBar />
            </div>
        </div>
    );
}