
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { Pagination } from '../../Common';
import { changeEditorValue, dateFormation, gameBigOpen, getGameLaunch, getGameList, getLocalStringNumber, getLocalstringNumber, isEmpty } from '../../../Utils';
import { pageActions } from '../../../store';
import { api_url, resource_url } from '../../../Utils/keys';
import { request } from '../../../Utils/api';

export { FreeSlotDetail };



const FreeSlotDetail = ({
    onBtnClick,
    lists,
    setActive,
    active,
    length,
    menuLists,
}) => {

    const dispatch = useDispatch();

    const userData = useSelector(state => state.user.data)

    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });
    const [search, setSearch] = useState("");
    const onChange = (e) => {
        const { name, value } = e.target;
        setSearch(value);
    }
    const [gameLists, setGameLists] = useState([]);
    const [showSlotDetail, setShowSlotDetail] = useState(false);
    const [slotActive, setSlotActive] = useState(0);


    const onShowSlotDetail = (index) => {
        setShowSlotDetail(true)
        setSlotActive(index)
        const params = {
            typeId: gameLists[index].item.id,
        }
        request.post(api_url + "/api/FreeSlot/newListDistinction", params)
    }

    const onSetSlotActive = (key) => {
        if (key < 0 || key >= gameLists.length) {
            setSlotActive(0);
        } else {
            setSlotActive(key);
        }
    }

    const onSlotBtnClick = () => {
        setShowSlotDetail(!showSlotDetail)
        setSlotActive(-1)
    }

    const getList = (page = 1, perPage = 10) => {
        setPageInfo({
            ...pageInfo,
            curPage: page,
            perPage: Number(perPage)
        });
        const params = {
            providerId: lists.id,
        }
        getGameList(params)
            .then(data => {
                !isEmpty(data.list) && data.list.sort((a, b) => {
                    return b.viewCnt - a.viewCnt;
                })
                let gameList = [];
                data.list.forEach((item, index) => {
                    if (index < data.list.length * 0.1) {
                        gameList.push({ "item": item, "best": true })
                    } else {
                        gameList.push({ "item": item, "best": false })
                    }
                })
                gameList.sort((a, b) => {
                    return b.item.created_date - a.item.created_date;
                })
                setGameLists(gameList);
                dispatch(pageActions.setLoading(false));
            })
    }

    useEffect(() => {
        getList();
    }, [lists]);

    const onSetActive = (key) => {
        setActive(key);
        setShowSlotDetail(false)
    }

    const getNormalImg = e => {
        e.target.src = `../imgs/slotgame1.webp`;
    };

    const onClickList = (list) => {
        dispatch(pageActions.setLoading(true));
        const params = {
            ...list,
            type: "Slot",
            username: userData.loginId,
            ipADD: localStorage.getItem('client-ip')
        }
        getGameLaunch(params);
    }

    const onClickBigView = (link) => {
        if (!isEmpty(link)) {
            gameBigOpen(link)
        }
    }

    return (
        <div className="freegame_board_list">

            <div className="page_seo_tit_box">
                <h2>{lists.name.name_kor} 무료 슬롯</h2>
                <p>{lists.detail}
                </p>
            </div>

            <div className="board">
                <div className="freegame_search_area">
                    <div className="game_title">
                        <dl>
                            <dt>
                                {lists.name.name_kor}
                            </dt>
                            <dd>총 <strong>{gameLists.length}</strong>개의 게임이 있습니다.</dd>
                        </dl>
                    </div>

                    <div className="searchbox">
                        <div >
                            <span>
                                <input type="text" placeholder="게임명 검색" name="search" value={search} onChange={onChange} /></span>
                            <span>
                                <button type="submit">
                                    <i className="fa-solid fa-magnifying-glass" aria-hidden="true"></i>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>


                <div className="freegame_category game_menu_box">
                    <ul style={{ display: "flex", overflowX: "auto", alignItems: "center", justifyContent: "start", height: "100%", padding: "0 10px" }}>
                        {menuLists &&
                            menuLists.map((item, index) => {
                                return (
                                    <li key={index} className={active === index ? "active" : ""}>
                                        <div className='cursor-pointer' title={item.name.name_kor} onClick={() => onSetActive(index)}>
                                            {item.name.name_kor}
                                        </div>
                                    </li>
                                )
                            })

                        }
                    </ul>
                </div>

                {!showSlotDetail &&
                    <>
                        <div>
                            <div className="gallery_list free_game_list">
                                <ul>
                                    {gameLists &&
                                        gameLists.filter(gameList => { const patt = new RegExp(search, "i"); return patt.test(gameList.item.title) || isEmpty(search); }).map((gameList, index) => {
                                            return (
                                                <li key={index} onClick={() => onShowSlotDetail(index)} >
                                                    <div className="box">

                                                        {gameList.item.newList ?
                                                            <div className="point_icon">
                                                                <img src="../imgs/freegame_new.gif" alt="new게임" />
                                                            </div>
                                                            :
                                                            (gameList.best &&
                                                                <div className="point_icon">
                                                                    <img src="../imgs/freegame_best.gif" alt="best게임" />
                                                                </div>
                                                            )
                                                        }
                                                        <div className=" cursor-pointer upper_link"
                                                            title={`${gameList.item.title}`}></div>
                                                        <div className="imgs">
                                                            {gameList.item.type === 1 ?
                                                                <img
                                                                    src={gameList.item.mainImg}
                                                                    alt={`${gameList.item.title}`}
                                                                    onError={getNormalImg}
                                                                />
                                                                :
                                                                <img
                                                                    src={`${resource_url}/${gameList.item.mainImg}`}
                                                                    alt={`${gameList.item.title}`}
                                                                    onError={getNormalImg}
                                                                />
                                                            }
                                                        </div>
                                                        <div className="tit">{`${gameList.item.title}`}</div>

                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="border_button mt20 mb20">
                            <div className="btn-group" role="group" aria-label="..." style={{ display: "flex" }}>
                                <button className="btn btn-primary btn-sm" onClick={onBtnClick}>목록</button>
                            </div>
                        </div>
                    </>
                }
                {(showSlotDetail && !isEmpty(gameLists[slotActive].item)) &&
                    <>
                        <div className="contents-view">
                            <div id="post-content">
                                <div className="post_content">
                                    <section className="section_box" id="section3" style={{ margin: "0px" }}>
                                        <ul>
                                            <li>
                                                <p>
                                                    <iframe src={gameLists[slotActive].item.gameLink}>
                                                    </iframe>
                                                </p>
                                                {!gameLists[slotActive].item.show &&
                                                    <>
                                                        <div className="magazine_btn_box">
                                                            <h3 style={{ color: "#c9c9c9" }}>게임정보</h3>
                                                        </div>
                                                        <table className="table table-bordered table_custom4" style={{ position: "relative", marginTop: "5px" }}>
                                                            <tbody>
                                                                <tr>
                                                                    <th>타이틀</th>
                                                                    <td>{gameLists[slotActive].item.title}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>제작사</th>
                                                                    <td>{gameLists[slotActive].item.provider.name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>출시일</th>
                                                                    <td>{dateFormation(gameLists[slotActive].item.start_date, 3)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>지원기기</th>
                                                                    <td>{gameLists[slotActive].item.assister}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>페이라인</th>
                                                                    <td>{gameLists[slotActive].item.payline}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>최소 베팅</th>
                                                                    <td>{getLocalStringNumber(gameLists[slotActive].item.minBet)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>최대 베팅</th>
                                                                    <td>{getLocalStringNumber(gameLists[slotActive].item.maxBet)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>맥스윈</th>
                                                                    <td>{getLocalstringNumber(gameLists[slotActive].item.maxWin)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>RTP</th>
                                                                    <td>{gameLists[slotActive].item.RTP}%</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <div className="magazine_btn_box">
                                                            <p style={{ fontSize: "13px", color: "#c9c9c9" }}>{gameLists[slotActive].item.comment}</p>
                                                        </div>
                                                    </>
                                                }
                                            </li>
                                        </ul>
                                    </section>
                                </div>
                                <div className="hidden_keyword"></div>
                            </div>
                        </div>
                        <div className="border_button mt20 mb20">
                            <div role="group" aria-label="..." style={{ display: "flex", justifyContent: "space-between" }}>
                                <div className="btn-group">
                                    <button className="btn btn-primary btn-sm" onClick={onSlotBtnClick}>목록</button>
                                    <button className="btn btn-primary btn-sm" onClick={() => onSetSlotActive(slotActive + 1)}>다음</button>
                                    <button className="btn btn-primary btn-sm" onClick={() => onSetSlotActive(slotActive - 1)}>이전</button>
                                </div>
                                <button className="btn full-btn" onClick={() => onClickBigView(gameLists[slotActive].item.gameLink)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-fullscreen" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z" />
                                    </svg>&nbsp;
                                    전화면
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 4.71875 3.28125 L 3.28125 4.71875 L 10.5625 12 L 5 12 L 5 14 L 14 14 L 14 5 L 12 5 L 12 10.5625 Z M 27.28125 3.28125 L 20 10.5625 L 20 5 L 18 5 L 18 14 L 27 14 L 27 12 L 21.4375 12 L 28.71875 4.71875 Z M 5 18 L 5 20 L 10.5625 20 L 3.28125 27.28125 L 4.71875 28.71875 L 12 21.4375 L 12 27 L 14 27 L 14 18 Z M 18 18 L 18 27 L 20 27 L 20 21.4375 L 27.28125 28.71875 L 28.71875 27.28125 L 21.4375 20 L 27 20 L 27 18 Z" /></svg> */}
                                    
                                </button>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    );
};
