

import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { dateFormation, isEmpty, isMobilePage, toastr } from "../../Utils";
import { Pagination } from "../Common";
import { eventActions, pageActions, userActions } from "../../store";
import { Winwheel } from "../Common";
import { RightSideBar, SideBar } from "../Layout";


export { RouletteContent };

function RouletteContent() {

  const dispatch = useDispatch();
  let roulette = useSelector(state => state.event.roulette)
  let showResults = useSelector(state => state.event.showResults)
  const [pageInfo, setPageInfo] = useState({
    curPage: 1,
    totalPage: 1,
    perPage: 10,
  });
  const [onlyClick, setOnlyClick] = useState(true)

  const history = isEmpty(roulette) || isEmpty(roulette.history) ? [] : roulette.history;
  const [theWheel, setTheWheel] = useState({});
  const [winMoney, setWinMoney] = useState(0);
  const [allowRoulette, setAllowRoulette] = useState(false);

  useEffect(() => {
    let rouletteList = roulette.roulette.list
    if (isEmpty(rouletteList)) return;
    let theWheel = getWinWheelObject(getFixedRouletteArray(rouletteList));
    setTheWheel(theWheel);
  }, [roulette.roulette.list])

  const getHistory = (page = 1, perPage = 10) => {
    setPageInfo({
      ...pageInfo,
      curPage: page,
      perPage: Number(perPage)
    })
    const params = {
      page: page,
      perPage: perPage,
    }
    dispatch(eventActions.getRouletteHistory(params));
  }

  const resetWheel = () => {
    let retheWheel = theWheel;
    retheWheel.stopAnimation(false);  // Stop the animation, false as param so does not call callback function.
    retheWheel.rotationAngle = 0;     // Re-set the wheel angle to 0 degrees.
    retheWheel.draw();                // Call draw to render changes to the wheel.
    setTheWheel(retheWheel);
  }
  const getWinWheelObject = data => {
    const mode = isMobilePage();
    return new Winwheel({
      'numSegments': data.length,     // Specify number of segments.
      'outerRadius': mode ? 180 : 260,   // Set outer radius so wheel fits inside the background.
      'textFontSize': mode ? 15 : 28,    // Set font size as desired.
      'segments':        // Define segments including colour and text.
        data,
      'animation':           // Specify the animation to use.
      {
        'type': 'spinToStop',
        'duration': 5,     // Duration in seconds.
        'spins': 10,     // Number of complete spins.
      }
    })
  }

  const getColor = (index) => {
    switch (index) {
      case 0: {
        return '#295197';
      }
      case 1: {
        return '#ec7400';
      }
      default:
        return;
    }
  }

  const getTextColor = (index) => {
    switch (index) {
      case 0: {
        return '#FFF';
      }
      case 1: {
        return '#2c4369';
      }
      default:
        return;
    }
  }

  const getFixedRouletteArray = data => {
    let resData = [];
    data.forEach((element, index) => {
      resData.push({
        'fillStyle': getColor(index % 2),
        'text': Number(element.bonus).toLocaleString() + 'P',
        'textFillStyle': getTextColor(index % 2)
      })
    });
    // 
    return resData;
  }



  const StartSpin = () => {
    setOnlyClick(false)
    if (onlyClick) {
      if (roulette.leftCount === 0) {
        return toastr.warning("남은 빅휠개수가 없습니다.")
      }
      let changeTheWheel = theWheel;
      resetWheel()
      setWinMoney(0);
      setAllowRoulette(true);
      setTheWheel(changeTheWheel);
      dispatch(eventActions.doRoulette());
    }
  }
  useEffect(() => {
    if (isEmpty(roulette.bonus)) return;
    if (allowRoulette) {
      let changeTheWheel = theWheel;
      let loading = false
      const index = roulette.roulette.list.findIndex(item => item.bonus === roulette.bonus);
      let stopAt = changeTheWheel.getRandomForSegment(index + 1);
      changeTheWheel.animation.stopAngle = stopAt;
      changeTheWheel.startAnimation();
      setTimeout(() => {
        getHistory();
        dispatch(userActions.getUserInfo());
        setWinMoney(roulette.bonus);
        setAllowRoulette(false);
        dispatch(eventActions.setShowResults(true))
        setOnlyClick(true)
      }, 5000);
    }

  }, [allowRoulette, roulette.bonus])
  useEffect(() => {
    getHistory();
    dispatch(eventActions.getRouletteRules())

  }, [])

  const onSetPage = (page) => {
    dispatch(pageActions.setPageData({
      page: Number(page),
      totalPage: page.totalPage
    }))
  }


  const canvasSize = isMobilePage() ? 360 : 536;

  return (
    <div className="container">
      <div className="row" style={{ margin: "0" }}>
        <div className="main_area">
          {showResults &&
            <div id="resultLayer">
              <button className="close_btn"><img src="../imgs/bigwheel/result_x.png" alt="레이어 닫기" onClick={() => dispatch(eventActions.setShowResults(false))} /></button>
              <div className="result-bg">
                <img src="../imgs/bigwheel/result_bg.png" alt="결과 배경" />
                <div className="result-content">
                  <span>{Number(winMoney).toLocaleString()}&nbsp;P</span>
                </div>
              </div>
            </div>}
          <div className="roulette">
            <canvas id="canvas" className="roulette_rotate_plate" width={canvasSize} height={canvasSize}></canvas>
            <div className="count">가능 횟 수 : <span id="bigwheel_coupon" className="num">{isEmpty(roulette.leftCount) ? 0 : roulette.leftCount}</span> 회</div>
            <div className="arrow"></div>
            <div className="border"></div>
            <div className="spin" id="start_btn">
              <div className="spin_icon" onClick={e => { e.preventDefault(); StartSpin(); }}></div>
              <div className="spin_txt"></div>
            </div>
            <div className="bigwheel" id="bigwheel" style={{ transform: "rotate(287deg)", transformOrigin: "50% 50%" }}>
              <div className="bigwheel_no"></div>
            </div>
          </div>
          <div className="bigwheel_info_txt">
            <h2>빅휠 이용안내</h2>
            <dl>
              <dt>이용 방법</dt>
              <dd>스핀가능 횟수를 확인하시고 빅휠 중앙에 있는 스핀 버튼을 누르면 돌림판이 돌아갑니다. 스핀이 멈춘 곳 표시된 금액이 당첨된 금액이며 보유포인트로 자동 지급 됩니다.</dd>
              <dt>스핀 획득 방법</dt>
              <dd>1. 출석체크 연속 7회 달성시 빅휠 1회 이용권을 획득 하실 수 있습니다.</dd>
              <dd>2. 이벤트 보상으로 빅휠 이용권을 획득 하실 수 있습니다.</dd>
              <dd>당첨된 포인트는 PG슬롯 포인트와 동일하게 사용가능 합니다.
                <Link to="/notice" title="포인트 사용안내 바로가기">포인트 사용안내 바로가기</Link>
              </dd>
            </dl>
          </div>
          <div className="bigwheel_list">
            <h2>이용내역</h2>
            <div className="table_list ">
              <ul>
                <li className="head">
                  <dl>
                    <dt style={{ borderRight: "1px solid #292c45" }}>번호</dt>
                    <dt style={{ borderRight: "1px solid #292c45" }}>이용일시</dt>
                    <dt>당첨내역</dt>
                  </dl>
                </li>
                {!isEmpty(roulette.history) ?
                  roulette.history.map((list, index) => {
                    return (
                      <li key={index}>
                        <dl>
                          <dt style={{ borderRight: "1px solid #292c45" }}>{Number(pageInfo.perPage) * (Number(pageInfo.curPage) - 1) + index + 1}</dt>
                          <dt style={{ borderRight: "1px solid #292c45" }}>{dateFormation(list.created_date, 9)}</dt>
                          <dt>{list.winPoint}P</dt>
                        </dl>
                      </li>
                    )
                  })
                  :
                  <li>
                    <dl>
                      <dt>내역이 없습니다.</dt>
                    </dl>
                  </li>
                }
              </ul>
            </div>
          </div>
          <Pagination pageInfo={pageInfo} getList={getHistory} />
        </div>
        <SideBar />
      </div>
    </div>
  );
};