import React, { useRef } from 'react';
import { Link } from "react-router-dom";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { resource_url } from '../../Utils/keys';
import { ChangeNum_Letter, isEmpty } from '../../Utils';

export { BoardBox };


const bannerList = [
    {
        img: "../imgs/article/verifiedsite1.webp",
        title: "골든시티 카지노사이트",
        money: "보증금 4억원 [제휴사이트]"
    },
    {
        img: "../imgs/article/verifiedsite2.webp",
        title: "강남파티 슬롯사이트",
        money: "보증금 1억원 [제휴사이트]"
    },
    {
        img: "../imgs/article/verifiedsite3.webp",
        title: "샌즈 카지노사이트",
        money: "보증금 2억원 [제휴사이트]"
    },
    {
        img: "../imgs/article/verifiedsite4.webp",
        title: "터보 슬롯사이트",
        money: "보증금 3억원 [제휴사이트]"
    },
]

function BoardBox({ verifyLists }) {

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };


    return (
        <div className="board_box_custom">
            <div className="tit_area">
                <h2><i className="fas fa-medal" aria-hidden="true"></i> 온라인 슬롯 보증 사이트</h2>
                <p>온라인 슬롯 보증 사이트에서는 안전하게 온라인 슬롯 게임을 즐길 수 있는 플랫폼을 제공합니다.
                    여기서는 검증된 슬롯게임 공급 업체와 신뢰할 수 있는 플레이 환경이 보장됩니다.
                    사용자의 안전과 만족을 최우선으로 생각하는 슬롯 보증사이트 업체와 함께, 걱정 없이 게임을 즐겨보세요.</p>
            </div>

            <div className="pc_box warranty_main">
                <Link to="/verifiedsite" title="보증사이트 바로가기" className="upper_link"></Link>
                {!isEmpty(verifyLists) ?
                    <Carousel
                        swipeable={false}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        autoPlay={true}
                        autoPlaySpeed={4000}
                        keyBoardControl={true}
                        customTransition="all 0.5"
                        transitionDuration={1000}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        deviceType={"pc"}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                        centerMode={true}
                    >{
                            !isEmpty(verifyLists) ?

                                verifyLists.map((list, index) => {
                                    return (
                                        <div className="item" key={index}>
                                            <div className="item_nav_img" style={{ position: "relative", marginRight: "10px" }}>
                                                <img src={`${resource_url}/${list.images.titleImg}`} alt={list.siteNameEng} draggable="false" />
                                            </div>
                                            <div className="tit"><span className="hot">보증</span>{list.siteNameKor}</div>
                                            <div className="sub">보증금 {ChangeNum_Letter(list.verifyMoney)}원 [제휴사이트]</div>
                                        </div>
                                    )
                                })
                                :
                                bannerList.map((list, index) => {
                                    return (
                                        <div className="item" key={index}>
                                            <div className="item_nav_img" style={{ position: "relative", marginRight: "10px" }}>
                                                <img src={list.img} alt="" draggable="false" />
                                            </div>
                                            <div className="tit"><span className="hot">보증</span>{list.title}</div>
                                            <div className="sub">{list.money}</div>
                                        </div>
                                    )
                                })
                        }
                    </Carousel>
                    :
                    <div className="tit_area" style={{textAlign: "center"}}>
                        <p>내역이 없습니다.</p>
                    </div>
                }
            </div>
        </div>
    );
}