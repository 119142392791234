import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Pagination } from '../Common';
import { eventActions } from '../../store';
import { SlotPhraseDetail } from './components';
import { api_url, resource_url } from '../../Utils/keys';
import { dateFormation, isEmpty } from '../../Utils';
import { request } from '../../Utils/api';
import { RightSideBar, SideBar } from '../Layout';

export { SlotPhraseContent };

function SlotPhraseContent() {


    const dispatch = useDispatch();
    const slotDictionary = useSelector(state => state.event.slotDictionary);

    const [showDetail, setShowDetail] = useState(false);

    const [active, setActive] = useState(0);

    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });

    const [searchInputs, setSearchInputs] = useState({
        searchType: "",
        searchText: "",
    });

    const { searchType, searchText } = searchInputs;

    const onChange = (e) => {
        const { name, value } = e.target;
        setSearchInputs({
            ...searchInputs,
            [name]: value
        })
        if (name === "searchType") {
            const param = {
                searchType: value,
                searchText: searchText,
            }
            getList(1, pageInfo.perPage, param);
        }
    }

    const onShowDetail = (index) => {
        setShowDetail(true)
        setActive(index)
        const params = {
            type: "slotvocabularies",
            typeId: slotDictionary[index].id,
        }
        request.post(api_url + "/api/Article/viewcount", params)
    }

    const onBtnClick = () => {
        setShowDetail(!showDetail)
        setActive(-1)
    }

    const onSearch = () => {
        const param = {
            ...searchInputs
        }
        getList(1, pageInfo.perPage, param);
    }
    const onEnter = (e) => {
        if (e.key === "Enter") {
            onSearch();
        }
    }

    const getList = (page = 1, perPage = 10, search = searchInputs) => {
        setPageInfo({
            ...pageInfo,
            curPage: page,
            perPage: Number(perPage)
        })
        setSearchInputs(search)
        const params = {
            page: page,
            perPage: perPage,
            type: search.searchType,
            key: search.searchText,
        }
        dispatch(eventActions.getSlotDictionary(params));
    }
    useEffect(() => {
        getList();
    }, []);


    return (
        <div className="container">
            <div className="row" style={{ margin: "0" }}>
                <div className='main_area' >
                    <SlotPhraseDetail
                        onBtnClick={onBtnClick}
                        slotDictionary={slotDictionary}
                        setActive={setActive}
                        active={active}
                        length={slotDictionary.length}
                    />
                </div>
                <SideBar />
            </div>
        </div>
    );
}