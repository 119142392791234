

import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "../Common";
import { eventActions, pageActions, userActions } from "../../store";
import { RightSideBar, SideBar } from "../Layout";
import { isEmpty, no_comma, toastr, getLocalstringNumber, getLocalStringNumber, dateFormation } from "../../Utils";
import { request } from "../../Utils/api";
import { api_url } from "../../Utils/keys";
import { ModalBack } from "../Common/modal";


export { PointChangeContent };

function PointChangeContent() {

  const dispatch = useDispatch();
  let userData = useSelector(state => state.user.data)
  let pointConvertHistory = useSelector(state => state.event.pointConvertHistory)

  const [inputs, setInputs] = useState({
    changePoint: ""
  });
  const { changePoint } = inputs;
  const onChange = (e) => {

    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: no_comma(value)
    });
  }
  const onSubmit = () => {
    if (isEmpty(changePoint)) {
      onShowModal()
      return toastr.warning("포인트를 입력해주세요.")
    }
    const params = {
      point: Number(changePoint),
    }
    onShowModal()
    request.post(api_url + "/api/Mypage/goldChange", params)
      .then((res) => {
        onShowModal()
        toastr.success(res.message);
        getHistory();
        dispatch(userActions.getUserInfo())
      });
  }

  const onReset = () => {
    setInputs({
      changePoint: ""
    })
  }
  const [pageInfo, setPageInfo] = useState({
    curPage: 1,
    totalPage: 1,
    perPage: 10,
  });

  const [showModal, setShowModal] = useState(false);

  const onShowModal = () => {
    setShowModal(!showModal)
  }



  const getHistory = (page = 1, perPage = 10) => {
    setPageInfo({
      ...pageInfo,
      curPage: page,
      perPage: Number(perPage)
    })
    const params = {
      page: page,
      perPage: perPage,
    }
    dispatch(eventActions.getPointConvertHistory(params));
  }

  useEffect(() => {
    getHistory();
  }, [])
  return (
    <div className="container">
      <div className="row" style={{ margin: "0" }}>
        <div className="main_area">
          <div className="wrapmemo mb10 d-flex">
            보유포인트:
            <h5 className="ml10">{getLocalStringNumber(userData.point)}</h5>
          </div>
          <div className="wrapmemo">
            <ul>
              <div className="attendance_box text-center mb20 mt10">
                <li>
                  <input
                    type="text"
                    name="changePoint"
                    value={((Number(changePoint) === 0) || isNaN(changePoint)) ? "" : getLocalstringNumber(changePoint)}
                    onChange={onChange}
                  />
                </li>
                <li>
                  <button className="refresh" onClick={onReset}>
                    <span className="fa fa-refresh" ></span>
                  </button>
                </li>
                <li>
                  <button className="ok_btn" onClick={onShowModal}>포인트전환</button>
                </li>

              </div>
            </ul>
          </div>
          <div className="bigwheel_info_txt">
            <h2>포인트전환 이용안내</h2>
            <dl>
              <dt>이용 방법</dt>
              <dd>포인트 사용을 원하시는 경우, 보유골드로 전환 이후 시용이 가능합니다.</dd>
              <dd>포인트는 각 이벤트를 통해 적립이 됩니다.</dd>
              <dd>1회 전환 최소 금액은 10 포인트 이며 1골드 단위까지 전환 가능합니다 .</dd>
            </dl>
          </div>
          <div className="bigwheel_list">
            <h2>포인트전환내역</h2>
            <div className="table_list ">
              <ul>
                <li className="head">
                  <dl>
                    <dt style={{ borderRight: "1px solid #292c45" }}>번호</dt>
                    <dt style={{ borderRight: "1px solid #292c45" }}>시간</dt>
                    <dt style={{ borderRight: "1px solid #292c45" }}>전환후 포인트</dt>
                    <dt style={{ borderRight: "1px solid #292c45" }}>상세내용</dt>
                  </dl>
                </li>
                {!isEmpty(pointConvertHistory) ?
                  pointConvertHistory.map((list, index) => {
                    return (
                      <li key={index}>
                        <dl>
                          <dt style={{ borderRight: "1px solid #292c45" }}>{Number(pageInfo.perPage) * (Number(pageInfo.curPage) - 1) + index + 1}</dt>
                          <dt style={{ borderRight: "1px solid #292c45" }}>{dateFormation(list.created_date, 9)}</dt>
                          <dt style={{ borderRight: "1px solid #292c45" }}>{list.afterpoint}P</dt>
                          <dt style={{ borderRight: "1px solid #292c45" }}>{list.memo}</dt>
                        </dl>
                      </li>
                    )
                  })
                  :
                  <li>
                    <dl>
                      <dt>내역이 없습니다.</dt>
                    </dl>
                  </li>
                }
              </ul>
            </div>
          </div>
          {!isEmpty(pointConvertHistory) &&
            <Pagination pageInfo={pageInfo} getList={getHistory} />
          }
        </div>
        <SideBar />
      </div>
      {showModal &&
        <div className="dialog" style={{ display: showModal ? "flex" : "none", backgroundColor: !showModal && "#00000000", transition: "0.5s", scale: showModal ? "1" : "0" }} >
          <ModalBack onClose={onShowModal} />
          <div className="container popup_color" style={{ display: "block", zIndex: "1090" }}>
            <div className="swal2-icon swal2-warning swal2-icon-show d-flex">
              <div className="swal2-icon-content">!</div>
            </div>
            <div className="justify-center  d-flex betting_modal_text">
              <span style={{ width: "100%", textAlign: "center" }}>
                포인트를 전환하시겠습니까?
              </span>
            </div>
            <div className=" d-flex w-100 justify-content-center padding-15 betting_modal_text">
              <button className="btn btn-success btn-sm" style={{ width: "59px" }} onClick={onSubmit}>네</button>
              <button className="btn btn-danger btn-sm" onClick={onShowModal}>아니오</button>
            </div>
          </div>
        </div>

      }
    </div>
  );
};