import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const infoLists = [
    {
        name: "포인트몰", 
        value: "pointmall", 
        path: "/pointmall", 
        title: "포인트몰", 
        content: <Fragment> 회원가입 및 출석체크 혹은 슬롯리뷰, <br />슬롯리뷰, 빅윈, 맥스윈 영상 제보를 통한 혜택이 와르르!</Fragment>, 
        iconClassName: "fas fa-coins"
    },
    {
        name: "슬롯 정보", 
        value: "info", 
        path: "/info", 
        title: "슬롯 정보", 
        content: <Fragment> 카지노&amp;슬롯 관련 최신뉴스와 유용한정보를 업로드해 드립니다. 다양한 정보를 받아가시기 바랍니다.</Fragment>, 
        iconClassName: "fas fa-book-open"
    },
    {
        name: "고객센터", 
        value: "inquires", 
        path: "/inquires", 
        title: "고객센터", 
        content: <Fragment> 각종 문의사항은 고객센터 게시판 혹은 텔레그램으로 문의 주시기 바랍니다.</Fragment>, 
        iconClassName: "fas fa-comments"
    },
]

export { HomeInfoBox };

function HomeInfoBox() {
    return (
        <div className="info_box_area">
            <ul>
                {
                    infoLists.map((list, index) => {
                        return (
                            <li key={index}>
                                <Link to={list.path} title={list.name} className="upper_link"></Link>
                                <div className="box">
                                    <div className="icons"><i className={list.iconClassName}></i></div>
                                    <dl>
                                        <dt>{list.title}</dt>
                                        <dd>{list.content}</dd>
                                    </dl>
                                </div>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    );
}