import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { RightSideBar, SideBar } from '../Layout';
import { Pagination } from '../Common';
import { communityActions } from '../../store';
import { FollowArticleDetail } from './components';
import { resource_url } from '../../Utils/keys';
import { isEmpty } from '../../Utils';

export { FollowArticleContent };

function FollowArticleContent() {

    const dispatch = useDispatch();
    const followArticleList = useSelector(state => state.community.followArticleList);

    const [showDetail, setShowDetail] = useState(false);
    const [active, setActive] = useState(0)

    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });

    const onShowDetail = (index) => {
        setShowDetail(true)
        setActive(index)
    }

    const onBtnClick = () => {
        setShowDetail(!showDetail)
        setActive(-1)
    }

    const getList = (page = 1, perPage = 10) => {
        setPageInfo({
            ...pageInfo,
            curPage: page,
            perPage: Number(perPage)
        });
        const params = {
            page: page,
            perPage: perPage,
        }
        dispatch(communityActions.getFollowArticleList(params));
    }

    useEffect(() => {
        getList();
    }, []);

    return (
        <div className="container">
            <div className="row" style={{ margin: "0" }}>
                {showDetail &&
                    <FollowArticleDetail
                        followArticleList={followArticleList[active]}
                        onBtnClick={onBtnClick}
                    />
                }
                {!showDetail &&
                    <div className="main_area">
                        <div className="page_seo_tit_box">
                            <h2>실제 카지노를 배경으로 작성된 여러 이야기들</h2>
                            <p>온라인슬롯 유저들을 위한 오프라인 카지노 이야기! PG슬롯만의 재밌는 독점 컨텐츠가 준비되었습니다.</p>
                        </div>

                        {followArticleList &&
                            followArticleList.map((list, index) => {
                                return (
                                    <div className="casinostory_list" key={index}>
                                        <ul>
                                            <li>
                                                <div className="box">
                                                    <div className="imgs cursor-pointer" title={list.title} onClick={() => onShowDetail(index)}>
                                                        <img loading="lazy" src={`${resource_url}/${list.image}`} alt={list.title} />
                                                    </div>
                                                    <div className="caption">
                                                        <div className="tit">
                                                            <h3>{list.title}</h3>
                                                            <span className={`category ${list.type === 1 ? "" : "exit"}`}> {list.type === 1 ? "연재" : "완결"} </span>
                                                        </div>
                                                        <p>{list.content}</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                )
                            })
                        }
                        {isEmpty(followArticleList) &&

                            <div className="casinostory_list">
                                <ul>
                                    <li>
                                        <div className="box">

                                            <div className="caption"  style={{padding: "0px"}}>
                                                내역이 없습니다.
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                        }
                        {!isEmpty(followArticleList) &&
                            <Pagination
                                pageInfo={pageInfo}
                                setPageInfo={setPageInfo}
                                getList={getList}
                            />
                        }

                    </div>
                }
                <SideBar />
            </div>
        </div>
    );
}
