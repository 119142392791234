import React, { useState, useEffect } from 'react';
import { MiniGamePanel, MiniGameVideo, MiniBettingHist } from './components';
import { getMinigameUrl } from '../../Utils';

export { EOS1Content };


function EOS1Content() {

    const [url, setUrl] = useState("");

    useEffect(() => {
        getMinigameUrl(1).then(url => setUrl(url)).catch(err => {
            console.error('err: ', err);
        })
    }, [])
    return (
        <div className="main_area">
            <MiniGameVideo type="1" url={url}/>
            <MiniGamePanel type="1" />
            <MiniBettingHist type="1" />
        </div>
    );
}
