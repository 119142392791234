import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr, isEmpty, fixedNumber } from '../../../Utils';
import { miniActions } from '../../../store';

export { MiniGamePanel };

const bettingBtns = [
    {
        name: "",
        nameEng: "",
        value: 100,
        className: "",
        isImgBtn: true,
        img: "../imgs/game/chip_1.png",
    },
    {
        name: "",
        nameEng: "",
        value: 300,
        className: "",
        isImgBtn: true,
        img: "../imgs/game/chip_3.png",
    },
    {
        name: "",
        nameEng: "",
        value: 500,
        className: "",
        isImgBtn: true,
        img: "../imgs/game/chip_5.png",
    },
    {
        name: "",
        nameEng: "",
        value: 1000,
        className: "",
        isImgBtn: true,
        img: "../imgs/game/chip_10.png",
    },
    {
        name: "",
        nameEng: "",
        value: 3000,
        className: "",
        isImgBtn: true,
        img: "../imgs/game/chip_30.png",
    },
    {
        name: "",
        nameEng: "",
        value: 5000,
        className: "",
        isImgBtn: true,
        img: "../imgs/game/chip_50.png",
    },
    {
        name: "",
        nameEng: "",
        value: 10000,
        className: "",
        isImgBtn: true,
        img: "../imgs/game/chip_100.png",
    },
    {
        name: "지우기",
        nameEng: "DELETE",
        value: "DELETE",
        className: "del_btn",
        isImgBtn: false,
        img: "",
    },
    {
        name: "베팅하기",
        nameEng: "BET",
        value: "BET",
        className: "bet_btn",
        isImgBtn: false,
        img: "",
    },
]

function MiniGamePanel(props) {

    const { type } = props;

    const dispatch = useDispatch();
    const minigame = useSelector(state => state.mini)
    const userData = useSelector(state => state.user.data)

    const isAuthenticated = useSelector(state => state.user.isAuthenticated)

    const [money, setMoney] = useState(0);
    const [popup, setPopup] = useState(false);
    const [betRate, setBetRate] = useState(fixedNumber(1));

    useEffect(
        () => {
            let price = 1;
            price = price * Number(minigame.data.rate);
            setBetRate(price);
        },
        [minigame.data.odd_key]
    );

    const onSetMoney = value => {
        if (value === "DELETE") {
            dispatch(miniActions.clearMiniBetStore())
            setMoney(0);
        } else if (value === "BET") {
            setPopup(!popup);
        } else {
            setMoney(money + value);
        }
    };

    const onBetting = () => {
        setPopup(!popup);
    };


    const DoBetting = () => {
        setPopup(!popup);
        if (!isAuthenticated)
            return toastr.warning("로그인하고 이용해주세요.");
        if (isEmpty(money) || money === 0)
            return toastr.warning("포인트를 선택해주세요.");
        if (isEmpty(minigame.data))
            return toastr.warning("베팅을 선택해주세요.");

        let betavailbleMessage = "";
        if (money < 100)
            betavailbleMessage = "제한된 베팅금액보다 작게 베팅하셨습니다.";
        if (money > 10000)
            betavailbleMessage = "제한된 베팅금액보다 많게 베팅하셨습니다. ";

        if (!isEmpty(betavailbleMessage))
            return toastr.warning(betavailbleMessage);

        setMoney(0);
        dispatch(miniActions.doMiniBetting(money, type));
    };

    return (
        <div className="betting_area" style={{ pointerEvents: "auto" }}>


            <div className="bet_action_box">
                <div className="box">
                    <div className="money_data">
                        <div className="point">보유포인트 <strong id="balance_point">{Number(userData.point).toLocaleString()}</strong> point,</div>
                        <div className="point ml10">베팅 <strong id="bet_money">{Number(money).toLocaleString()}</strong> point,</div>
                        <div className="point">베팅율합계 <strong id="bet_money">{fixedNumber(betRate)}</strong> 배,</div>
                        <div className="point ml30">적중예상금액 <strong id="bet_money">{Number(money * fixedNumber(betRate)).toLocaleString()}</strong> point</div>
                    </div>
                    <div className={`chip_box ${type}`}>
                        <ul>
                            {
                                bettingBtns.map((btn, index) => {
                                    return (
                                        <li className={btn.className} key={index} onClick={() => onSetMoney(btn.value)}>
                                            {
                                                btn.isImgBtn ? <img src={btn.img} alt={btn.value} />
                                                    :
                                                    <Fragment>
                                                        <div className="txt">{btn.name}</div>
                                                        <div className="en">{btn.nameEng}</div>
                                                    </Fragment>
                                            }
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>

                    <div className="info">최소 100point 부터 10,000point 까지 100단위로 베팅하실 수 있습니다.</div>

                </div>
            </div>
            {popup &&
                <div className="dialog" style={{ display: popup ? "flex" : "none", backgroundColor: !popup && "#00000000", transition: "0.5s", scale: popup ? "1" : "0" }} >
                    <div className="container popup_color" style={{ display: "block", zIndex: "1090" }}>
                        <div className="swal2-icon swal2-warning swal2-icon-show d-flex">
                            <div className="swal2-icon-content">?</div>
                        </div>
                        <div className="justify-center  d-flex betting_modal_text">
                            <span style={{ width: "100%", textAlign: "center" }}>
                                베팅을 진행하시겠습니까?
                            </span>
                        </div>
                        <div className=" d-flex w-100 justify-content-center padding-15 betting_modal_text">
                            <button className="btn btn-success btn-sm" style={{width: "59px"}} onClick={DoBetting}>네</button>
                            <button className="btn btn-danger btn-sm" onClick={onBetting}>아니오</button>
                        </div>
                    </div>
                </div>

            }
        </div>
    )
}
