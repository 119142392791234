import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getLobbylist, isEmpty } from '../../Utils';
import { pageActions } from '../../store';
import { FreeSlotDetail } from './components';
import { resource_url } from '../../Utils/keys';
import { RightSideBar, SideBar } from '../Layout';


export { FreeSlotContent };

const slotLists = [
    {
        name: "프라그마틱",
        url: "https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?lang=ko&cur=KRW&websiteUrl=https%3A%2F%2Fclienthub.pragmaticplay.com%2F&gcpif=2273&gameSymbol=vs20fruitswx&jurisdiction=99&lobbyUrl=https://clienthub.pragmaticplay.com/slots/game-library/",
        img: "../imgs/slotimages/freeslot_pragmatic.png",
        hoverImg: "../imgs/slotimages/freeslot_pragmatic.png"
    },
    {
        name: "노리밋시티",
        url: "https://casino.nolimitcdn.com/loader/game-loader.html?game=LandOfTheFree&operator=VAVADA&language=en&device=desktop",
        img: "../imgs/slotimages/freeslot_nolimit.png",
        hoverImg: "../imgs/slotimages/freeslot_nolimit.png"
    },
    {
        name: "피지소프트",
        url: "https://m.pgsoft-games.com/1623475/index.html?l=en&ot=ca7094186b309ee149c55c8822e7ecf2&btt=2&from=https://www.pgsoftslots.com/&_refer=m.pg-redirect.net&or=static.pgsoft-games.com&_hv=1f8e1d3b",
        img: "../imgs/slotimages/freeslot_pgsoft.png",
        hoverImg: "../imgs/slotimages/freeslot_pgsoft.png"
    },
    {
        name: "플래이앤고",
        url: "https://qapromo01-cw.playngonetwork.com/casino/ContainerLauncher?gid=coltlightningfirestorm&pid=4&lang=en_GB&practice=1&demo=2&channel=desktop",
        img: "../imgs/slotimages/freeslot_playngo.png",
        hoverImg: "../imgs/slotimages/freeslot_playngo.png"
    },
    {
        name: "릴렉스게이밍",
        url: "https://d2drhksbtcqozo.cloudfront.net/casino/games-mt/linebusters/index.html?gameid=linebustersdd&jurisdiction=MT&channel=web&moneymode=fun&partnerid=10&beyondplay=true&fullscreen=false",
        img: "../imgs/slotimages/freeslot_relax.png",
        hoverImg: "../imgs/slotimages/freeslot_relax.png"
    },
    {
        name: "하바네로",
        url: "https://app-test.insvr.com/games/?brandid=a9d542ac-c9bb-e311-93f6-80c16e0883f4&brandgameid=64651d29-f15e-405d-9758-bef5547e9d64&mode=fun&locale=en&ifrm=1&lobbyurl=pm-closeGame",
        img: "../imgs/slotimages/freeslot_habanero.png",
        hoverImg: "../imgs/slotimages/freeslot_habanero.png"
    },
    {
        name: "씨큐나인",
        url: "https://h5c.cqgame.games/h5/242/?language=en&token=guest123",
        img: "../imgs/slotimages/freeslot_cq9.png",
        hoverImg: "../imgs/slotimages/freeslot_cq9.png"
    },
    {
        name: "부운고",
        url: "https://bng.games/play/golden_teapot/?lang=ko",
        img: "../imgs/slotimages/freeslot_boongo.png",
        hoverImg: "../imgs/slotimages/freeslot_boongo.png"
    },
    {
        name: "썬더킥",
        url: "https://forfun.thunderkick.com/gamelauncher/play/generic?device=desktop&gameId=tk-s1-g76-94&operatorId=2&playMode=demo",
        img: "../imgs/slotimages/freeslot_thunderkick.png",
        hoverImg: "../imgs/slotimages/freeslot_thunderkick.png"
    },
    {
        name: "푸쉬게이밍",
        url: "https://player.eu.regulated.pushgaming.com/hive/b2c/game/retrosweets-01/client/index.html?igpCode=wildz&guest=true&mode=demo&lang=en&country=GB&meshSessionId=e227135b-7221-46c4-bea7-0c564d95d084&ccyCode=EUR&jurisdiction=NA",
        img: "../imgs/slotimages/freeslot_push.png",
        hoverImg: "../imgs/slotimages/freeslot_push.png"
    },
]

function FreeSlotContent() {

    const dispatch = useDispatch();

    const [lists, setLists] = useState([]);
    const [showDetail, setShowDetail] = useState(false);
    const [active, setActive] = useState(0);


    const onShowDetail = (index) => {
        setShowDetail(true)
        setActive(index)
    }

    const onBtnClick = () => {
        setShowDetail(!showDetail)
        setActive(-1)
    }

    const getLists = () => {
        getLobbylist().then(data => {
            setLists(data);
            dispatch(pageActions.setLoading(false));
        })
    }

    useEffect(() => {
        getLists();

    }, []);

    const getNormalImg = e => {
        e.target.src = `../imgs/freeslot_pragmatic.png`;
    };
    const getNormalHoverImg = e => {
        e.target.src = `../imgs/freeslot_pragmatic_active.png`;
    };


    return (
        <div className="container">
            <div className="row" style={{ margin: "0" }}>
                <div className='main_area'>
                    {showDetail &&
                        <FreeSlotDetail
                            onBtnClick={onBtnClick}
                            lists={lists[active]}
                            menuLists={lists}
                            setActive={setActive}
                            active={active}
                            length={lists.length}
                        />

                    }
                    {!showDetail &&
                        <>
                            <div className="freegame_search_area">
                                <div className="game_title">
                                    <div className="keyword">온라인슬롯게임을 무료로 체험해보세요!</div>
                                </div>
                            </div>
                            <div className="new_freegame_list">
                                <ul style={{ display: "flex", flexWrap: "wrap" }}>
                                    { !isEmpty(lists) ?
                                        lists.map((list, index) => {
                                            return (
                                                <li key={index} onClick={() => onShowDetail(index)}>
                                                    <div className="imgs default">
                                                        <img src={`${resource_url}/${list.mainImg}`} onError={getNormalImg} alt={list.name.name_kor} width="100%" height="100%" />
                                                    </div>
                                                    <div className="imgs active">
                                                        <img src={`${resource_url}/${list.mainImg}`} onError={getNormalHoverImg} alt={list.name.name_kor} loading="lazy" width="100%" height="100%" />
                                                    </div>
                                                    <div className="tit">{list.name.name_kor}</div>
                                                </li>
                                            )
                                        }) :
                                        <li>
                                            <div className="tit">내역이 없습니다.</div>
                                        </li>
                                    }
                                </ul>
                            </div>


                            <div className="page_seo_tit_box">
                                <h2>인기 온라인 무료 슬롯 순위 TOP12</h2>
                                <p>인기 온라인 슬롯 순위 TOP12"는 슬롯 게임 애호가나 초보자들에게 현재 가장 인기 있는 온라인 슬롯 게임 12개를
                                    소개합니다.
                                    이 순위는 게임의 특성, 사용자 리뷰, 지급률, 그래픽과 사운드 퀄리티 등 다양한 기준을 통해 선정되었습니다.
                                    이 리스트를 통해 어떤 슬롯 게임이 현재 트렌드인지 한 눈에 파악할 수 있으며, 자신의 취향과 맞는 게임을 빠르게
                                    찾아볼 수 있습니다.</p>
                                <div className="imgs">
                                    <img src="../imgs/freeslot_main_img.jpg" alt="인기 온라인 무료 슬롯 순위 TOP12" /></div>
                                <ul>
                                    <li>1. 프라그마틱 플레이 - 프로그마틱은 한국 게이머들 사이에서 매우 인기가 많은 슬롯 게임 제공자로 알려져
                                        있으며, 다양한 테마와 고급 그래픽을 자랑합니다. 또한, 대다수의 슬롯 게임들은 구매 가능한 옵션이
                                        있습니다.</li>
                                    <li>2. 노리밋시티 슬롯 - 노리밋시티 슬롯은 X기능을 통해 독특한 게임 경험을 제공하며, 하드코어 스타일의
                                        그래픽과 영상이 특징입니다. 또한 맥스윈 배당률이 높아, 큰 수익을 기대할 수 있는 슬롯 게임 회사입니다.</li>
                                    <li>3. PG 소프트 슬롯 - PGSOFT는 아시아를 기반으로 한 온라인 슬롯 개발사로, 아시아 문화를 반영한
                                        풍부한 스토리와 높은 품질의 그래픽을 제공합니다. 특히 모바일에 최적화되어 편리하게 즐길 수 있습니다.</li>
                                    <li>4. 플레이엔고 - 플레이엔고는 스웨덴 기반의 온라인 슬롯 게임 개발 회사이며, 다양한 테마와 뛰어난
                                        그래픽, 그리고 창의적인 보너스 기능을 자랑합니다. 높은 RTP와 변동성 옵션을 제공합니다.</li>
                                    <li>5. 릴렉스게이밍 - 릴렉스게이밍은 창의적인 슬롯 게임을 제공하는 개발 회사입니다. 뛰어난 그래픽과 복잡한
                                        보너스 기능이 특징이며, 높은 RTP로 다양한 수익 기회를 제공합니다. 모바일과 데스크탑 호환 가능.</li>
                                    <li>6. 하바네로 - 하바네로는 다양한 테마와 높은 RTP를 자랑하는 온라인 슬롯 개발 회사입니다. 사용자
                                        친화적인 디자인과 복잡하지 않은 보너스 기능이 특징입니다. 모바일과 데스크탑에서 쉽게 즐길 수 있습니다.</li>
                                    <li>7. 시큐나인 - 시큐나인은 아시아 출신의 온라인 슬롯 개발사로, 독특한 테마와 강력한 보너스 기능을
                                        제공합니다. 높은 RTP와 뛰어난 그래픽으로 플레이어에게 높은 수익과 재미를 보장합니다.</li>
                                    <li>8. 부운고 - 부운고는 HTML5 기반의 고화질 슬롯 게임을 제공하는 개발 회사입니다. 다양한 테마와
                                        창의적인 보너스 기능이 특징이며, 크로스 플랫폼 호환성을 지녔습니다. 높은 RTP로 플레이어에게 수익 기회를
                                        제공합니다.</li>
                                    <li>9. 선더킥 - 선더킥은 스웨덴 기반의 슬롯 게임 개발 회사입니다. 아트스타일이 독특하고, 창의적인 보너스
                                        기능과 높은 RTP를 자랑합니다. 다양한 플랫폼에서 원활한 게임 경험을 제공하며, 플레이어에게 높은 수익
                                        기회를 줍니다</li>
                                    <li>10. 푸쉬게이밍 - 푸쉬게이밍은 잼민 잘스(Jammin Jars)라는 슬롯 게임이 유명한 슬롯 회사로,
                                        혁신적인 디자인과 게임 메커니즘을 제공합니다. 높은 RTP로 플레이어에게 수익과 재미를 동시에 선사합니다.</li>
                                    <li>11. 와즈단 - 와즈단은 '이집트:신' 같은 대표 게임으로 알려진 온라인 슬롯 개발사입니다. 3D 고화질
                                        그래픽과 다채로운 테마를 통해 독특한 슬롯 게임 경험을 제공합니다.</li>
                                    <li>12. 블루프린트 - 블루프린트는 메가웨이 슬롯 시리즈로 큰 인기를 끌고 있는 온라인 슬롯 개발사입니다.
                                        메가웨이 게임을 좋아하는 플레이어에게는 이 회사의 슬롯을 강력하게 추천할 수 있습니다.</li>
                                </ul>
                            </div>

                            <div className="page_seo_conts_box">
                                <h2>무료 슬롯 체험 가이드 - 무제한 플레이로 온라인 슬롯 마스터하기</h2>
                                <p>무료 슬롯 체험 가이드는 온라인 슬롯 게임에 입문한 사용자나 다양한 게임과 전략을 시험해 보고 싶은 사람들을 위한
                                    완벽한 안내서입니다.
                                    무제한 플레이의 장점을 최대한 활용해 게임의 다양한 룰과 전략을 익히고, 실제 돈을 거는 베팅에 앞서 자신감을 쌓을
                                    수 있습니다.
                                    이 가이드를 통해 리스크 없이 다양한 게임을 체험하고, 사용자 리뷰와 피드백을 바탕으로 자신만의 승리 전략을 세울
                                    수 있습니다.
                                    따라서 이 가이드는 온라인 슬롯 게임을 '마스터'하고 싶은 모든 이들에게 필수적인 컨텐츠입니다.</p>
                                <div className="section_box">
                                    <h3>1. 무료 슬롯 소개</h3>
                                    <ul>
                                        <li>- 무료 슬롯 체험의 중요성: 무료 슬롯 체험은 게임을 처음 시작하는 사용자나 다양한 슬롯 게임을
                                            시도해 보고 싶은 사람들에게 완벽한 기회를 제공합니다.</li>
                                        <li>- 대상 사용자: 초보자, 다양한 게임을 탐색하고자 하는 사용자, 또는 게임 전략을 테스트하고 싶은
                                            사용자 등</li>
                                    </ul>
                                </div>

                                <div className="section_box">
                                    <h3>2. 무료 슬롯 장점</h3>
                                    <ul>
                                        <li>- 다양한 게임 체험: 수백 가지 이상의 슬롯 게임을 무료로 체험할 수 있습니다.</li>
                                        <li>- 게임 룰 익히기: 게임의 기본적인 룰과 전략을 안전하게 연습할 수 있습니다.</li>
                                        <li>- 리스크 없는 연습: 실제 돈을 걸지 않고도 게임을 즐길 수 있으므로 리스크가 없습니다.</li>
                                    </ul>
                                </div>
                                <div className="section_box">
                                    <h3>3. 무제한 플레이의 특징</h3>
                                    <ul>
                                        <li>- 자유로운 게임 선택: 원하는 게임을 자유롭게 선택하여 플레이할 수 있습니다.</li>
                                        <li>- 플레이 제약 없음: 시간이나 횟수에 대한 제약 없이 무제한으로 게임을 즐길 수 있습니다.</li>
                                    </ul>
                                </div>
                                <div className="section_box">
                                    <h3>4. 실제 베팅 준비</h3>
                                    <ul>
                                        <li>- 연습을 통한 자신감 향상: 무료 슬롯을 통해 충분한 연습을 하고 자신감을 키울 수 있습니다.</li>
                                        <li>- 승리 전략 세우기: 다양한 게임을 체험하면서 자신만의 승리 전략을 세울 수 있습니다.</li>
                                    </ul>
                                </div>
                                <div className="section_box">
                                    <h3>5. 사용자 경험</h3>
                                    <ul>
                                        <li>- 플레이어 리뷰와 피드백: 다른 사용자들의 경험과 리뷰를 통해 더 나은 게임 선택과 전략을 세울 수
                                            있습니다.</li>
                                        <li>- 무료 슬롯에서 얻을 수 있는 레슨: 게임의 다양한 면을 알고, 실제 베팅에 활용할 수 있는 유용한
                                            레슨을 얻을 수 있습니다.</li>
                                    </ul>
                                </div>
                                <div className="section_box">
                                    <h3>6. 다음 단계로 나아가기</h3>
                                    <ul>
                                        <li>- 무료 슬롯 체험의 장점과 한계: 무료 슬롯 체험은 다양한 게임을 경험하고 게임 룰을 익히는 등
                                            여러 장점이 있지만, 실제 베팅과는 다른 경험을 제공한다는 한계가 있습니다.</li>
                                        <li>- 다음 단계로 나아가기: 무료 체험을 충분히 활용한 뒤, 리얼 머니를 사용한 게임으로 나아갈 수
                                            있습니다.</li>
                                    </ul>
                                </div>

                            </div>


                            <div className="page_seo_tit_box qna_box">
                                <h2>무료 슬롯 체험 Q&amp;A</h2>

                                <div className="section_box">
                                    <h3>무료 슬롯 체험에서 가장 많이 얻을 수 있는 장점은 무엇인가요?</h3>
                                    <ul>
                                        <li>1. 게임 룰 익히기: 다양한 슬롯 게임의 룰과 메커니즘을 빠르게 이해하고 익힐 수 있습니다.</li>
                                        <li>2. 리스크 없음: 실제 돈을 걸지 않기 때문에 금전적인 리스크 없이 게임을 즐길 수 있습니다.</li>
                                        <li>3. 다양성 탐색: 여러 종류의 슬롯 게임을 경험해 볼 수 있어, 나중에 리얼 머니 게임을 할 때
                                            어떤 게임이 자신에게 맞는지 미리 알 수 있습니다.</li>
                                        <li>4. 전략 연습: 게임별로 적용되는 다양한 전략과 테크닉을 시험해 볼 수 있습니다.</li>
                                        <li>5. 엔터테인먼트: 무료이기 때문에 단순한 즐거움과 엔터테인먼트를 경험할 수 있습니다.</li>
                                        <li>6. 사용자 인터페이스 익히기: 각 게임의 사용자 인터페이스와 컨트롤에 익숙해질 수 있어, 나중에
                                            실제 게임을 할 때 더 빠르고 효율적으로 플레이할 수 있습니다.</li>
                                    </ul>
                                    <p>이러한 장점들을 통해 무료 슬롯 체험은 사용자가 리얼 머니 게임으로 넘어갈 때 더욱 자신감을 가지고 게임을
                                        즐길 수 있게 해줍니다.</p>
                                </div>

                                <div className="section_box">
                                    <h3>무료 슬롯 그리고 리얼 머니 슬롯 게임의 주요 차이점은 무엇인가요?</h3>
                                    <p>무료 슬롯 체험과 리얼 머니 슬롯 게임의 가장 큰 차이점은 금전적인 요소입니다.
                                        무료 슬롯 체험에서는 실제 돈을 거는 것이 없으므로, 게임의 규칙이나 메커니즘, 또는 다양한 슬롯 게임을
                                        무료로 경험할 수 있습니다.
                                        이는 게임을 처음 접하는 사용자나 특정 게임의 룰을 익히고자 하는 사람들에게 유용합니다.</p>
                                    <p>반면, 리얼 머니 슬롯 게임은 실제 돈을 걸고 플레이하기 때문에 더 높은 긴장감과 리스크가 동반됩니다.
                                        이로 인해 이길 경우에는 실제 수익을 얻을 수 있으나, 잃을 경우에는 금전적 손실이 발생합니다.
                                        따라서 무료 슬롯에서 얻은 경험과 실제 돈을 건 게임은 다르게 느껴질 수 있습니다.</p>
                                </div>

                                <div className="section_box">
                                    <h3>무료 슬롯 체험을 통해 어떤 게임 룰을 익힐 수 있나요?</h3>
                                    <p>무료 슬롯 체험을 통해 다양한 게임 룰을 익힐 수 있습니다.</p>
                                    <ul>
                                        <li>1. 베이직 룰: 슬롯 게임의 기본적인 룰, 즉 어떻게 스핀을 하고, 어떻게 당첨이 결정되는지를
                                            파악할 수 있습니다.</li>
                                        <li>2. 페이라인과 베팅: 다양한 페이라인 (당첨 라인)의 구조와 베팅 옵션에 대한 이해를 높일 수
                                            있습니다.</li>
                                        <li>3. 보너스 라운드: 게임에 따라 다양한 보너스 라운드와 특수 기능을 경험하고, 어떻게 활성화되는지를
                                            배울 수 있습니다.</li>
                                        <li>4. 심볼과 조합: 각 게임에 특화된 심볼들 (와일드, 스캐터 등)과 그 조합으로 얻을 수 있는
                                            당첨금에 대한 이해를 높일 수 있습니다.</li>
                                        <li>5. 게임 전략: 무료 체험을 통해 게임에 특화된 다양한 전략을 시험해 볼 수 있습니다. 예를 들어,
                                            어떤 시점에서 베팅 금액을 늘리거나 줄이는 것이 유리한지를 파악할 수 있습니다.</li>
                                        <li>6. 게임 속도와 유저 인터페이스: 게임의 진행 속도, 버튼 배치, 자동 스핀 옵션 등을 통해 사용자
                                            인터페이스에 익숙해질 수 있습니다.</li>
                                    </ul>
                                    <p>무료 슬롯 체험을 통해 이러한 다양한 게임 룰과 메커니즘을 익혀, 리얼 머니 게임에 더 자신감 있게 임할 수
                                        있습니다.</p>
                                </div>

                                <div className="section_box">
                                    <h3>무료 슬롯 체험을 충분히 경험한 뒤, 어떻게 리얼 머니 게임으로 전환할 수 있나요?</h3>
                                    <p>무료 슬롯 체험을 충분히 이용한 뒤, 리얼 머니 게임으로 전환하는 과정은 다음과 같습니다</p>
                                    <ul>
                                        <li>1. 게임 선택: 무료 체험을 통해 자신이 좋아하는 슬롯 게임이나 특정 기능, 테마를 파악합니다.</li>
                                        <li>2. 온라인 카지노 선택: 신뢰할 수 있는 온라인 카지노 사이트를 선택합니다. 라이센스, 리뷰,
                                            지원되는 결제 방법 등을 확인하세요.</li>
                                        <li>3. 회원가입 및 인증: 선택한 온라인 카지노에 회원가입을 하고 필요한 경우, 본인 인증을
                                            진행합니다.</li>
                                        <li>4. 예금: 카지노 계정에 리얼 머니를 입금합니다. 이때, 다양한 결제 방법이나 보너스 옵션을 확인할
                                            수 있습니다.</li>
                                        <li>5. 베팅 시작: 이제 리얼 머니로 선택한 슬롯 게임을 시작할 수 있습니다. 처음에는 작은 금액부터
                                            베팅하여 자신감을 쌓는 것이 좋습니다.</li>
                                        <li>6. 게임 전략: 무료 슬롯에서 익힌 게임 룰과 전략을 실제 게임에 적용합니다.</li>
                                        <li>7. 리스크 관리: 예산을 미리 설정하고, 일정 금액 이상의 손실이 발생하면 게임을 중단하는 등의
                                            리스크 관리 전략을 세웁니다.</li>
                                    </ul>
                                    <p>이러한 단계를 거쳐 무료 슬롯 체험에서 리얼 머니 게임으로 스무스하게 전환할 수 있습니다.</p>
                                </div>
                            </div>

                            <div className="page_seo_tit_box">
                                <h2>온라인 슬롯 무료 체험</h2>
                                <p>무료 슬롯은 슬롯 커뮤니티 PG슬롯에서 누구나 무료로 이용할 수 있는 플랫폼입니다.
                                    이 플랫폼에서는 게임 룰을 익히고 다양한 슬롯 게임을 경험하는 등의 여러 장점을 누릴 수 있습니다.
                                    그러나 실제 금전적인 베팅이 없기 때문에, 무료 슬롯은 긴장감이나 리스크 관리 측면에서 한계가 있을 수 있습니다.
                                    이러한 한계를 극복하고 더 깊은 슬롯 경험을 위해서는 PG슬롯 플랫폼을 활용하여 충분한 무료 체험을 한 뒤, 리얼
                                    머니 게임으로 전환할 필요가 있습니다.
                                    이렇게 하면 무료 체험에서 얻은 지식과 경험을 실제 게임에서도 활용할 수 있을 것입니다.</p>
                            </div>
                        </>}
                </div>
                <SideBar />
            </div>

        </div>
    );
}