import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { siteActions } from '../../store';

import { RightSideBar, SideBar } from '../Layout';
import { BoardBox } from './BoardBox';
import { TabBoard } from './TabBoard';
import { HomeFreeSlot, HomeFreeSlotLatest } from './HomeFreeSlot';
import { HomeInfoBox } from './HomeInfoBox';
import { HomeFakeSite } from './HomeFakeSite';
import { HomeFollowArticle } from './HomeFollowArticle';
import { HomeFAQ } from './HomeFAQ';
import { HomeVideo } from './HomeVideo';
import { resource_url } from '../../Utils/keys';
import { isEmpty } from '../../Utils';


export { HomeContent };

function HomeContent() {

    const dispatch = useDispatch();
    const mainPageList = useSelector(state => state.site.mainPageList)
    const mainBanner = useSelector(state => state.site.mainBanner)
    const casinoInfoLists = mainPageList.CasinoInfoLists
    const fakeLists = mainPageList.fakeLists
    const freeArticleLists = mainPageList.freeArticleLists
    const cooperationReviewLists = mainPageList.cooperationReviewLists
    const slotReviewLists = mainPageList.slotReviewLists
    const slotVideoLists = mainPageList.slotVideoLists
    const verifyLists = mainPageList.verifyLists
    const getList = () => {
        dispatch(siteActions.getMainPageList());
        dispatch(siteActions.getMainBannerUrl());
    }
    useEffect(() => {
        getList();
    }, []);

    const videoUrl = resource_url + "/" + mainBanner.url
    const youtubeUrl = mainBanner.url

    return (
        <div className="container">
            <div className="row" style={{ margin: "0" }}>
                <div className="main_area">
                    <div className="video_banner main_video_banner">
                        <video src={ mainBanner.status === 0 && (mainBanner.type === 2 ? videoUrl : youtubeUrl ) } muted autoPlay={true} loop={true}></video>
                    </div>
                    { !isEmpty(verifyLists) &&
                        <BoardBox verifyLists={verifyLists} />
                    }
                    <TabBoard
                        slotReviewLists={slotReviewLists}
                        slotVideoLists={slotVideoLists}
                        casinoInfoLists={casinoInfoLists}
                        freeArticleLists={freeArticleLists}
                        cooperationReviewLists={cooperationReviewLists}
                    />
                    <HomeInfoBox />
                    <HomeFAQ />
                </div>
                <SideBar/>
            </div>

        </div>
    );

}