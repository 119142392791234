import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { dateFormation, isEmpty, toastr } from '../../Utils';
import { Pagination } from '../Common';
import { RightSideBar, SideBar } from '../Layout';
import { request } from '../../Utils/api';
import { api_url } from '../../Utils/keys';
import { userActions } from '../../store';



export { AttendanceContent };

function AttendanceContent() {

    const dispatch = useDispatch();
    const attendanceList = useSelector(state => state.user.attendanceList)
    const myAttendanceList = useSelector(state => state.user.myAttendanceList)

    const [date, setDate] = useState(new Date(Date.now()));
    const [dateArray, setDateArray] = useState([]);
    const [inputs, setInputs] = useState({
        attendTitle: ""
    });

    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });


    const { attendTitle } = inputs;
    const onChange = (e) => {

        const { value, name } = e.target;
        setInputs({
            ...inputs,
            [name]: value
        });
    }
    const onSubmit = () => {
        if (isEmpty(attendTitle)) {
            return toastr.warning("인사말을 입력해주세요.")
        }
        const params = {
            title: attendTitle,
        }

        request.post(api_url + "/api/Mypage/attendance", params)
            .then((res) => {
                toastr.success(res.message);
                getList();
            });
    }

    const onReset = () => {
        setInputs({
            attendTitle: ""
        })
    }
    const getList = (page = 1, perPage = 10) => {
        setPageInfo({
            ...pageInfo,
            curPage: page,
            perPage: Number(perPage)
        })
        const params = {
            page: page,
            perPage: perPage,
        }
        dispatch(userActions.getAttendanceList(params));
        dispatch(userActions.getMyAttendanceList());
    }
    useEffect(() => {
        getList();
    }, []);

    const GetDateArray = date => {
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        const nextDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        const lastDay = nextDay.getDate();

        let array = [],
            i,
            j,
            cnt = 1;
        for (i = 0; cnt <= lastDay; i++) {
            let temporary = [];
            if (i === 0) {
                for (j = 0; j < firstDay.getDay(); j++) temporary.push(0);
                for (; j < 7; j++) temporary.push(cnt++);
            } else for (j = 0; j < 7 && cnt <= lastDay; j++) temporary.push(cnt++);
            for (j = temporary.length; j < 7; j++) temporary.push(0);
            array.push(temporary);
        }
        setDateArray(array);
    }

    let currentDate = dateFormation(new Date(date), 4);

    const ChangeDate = (param) => {
        const curDate = new Date(date);
        const now = new Date();
        curDate.setMonth(date.getMonth() + param);
        if (curDate > now)
            return;
        GetDateArray(curDate);
        setDate(curDate);
    }

    useEffect(() => {
        GetDateArray(date);
    }, []);


    return (
        <div className="container">
            <div className="row" style={{ margin: "0" }}>
                <div className="main_area">
                    <div className="video_banner">
                        <img src="../imgs/benner_check.png" alt="출석체크를 하시면 쿠폰 교환이 가능한 포인트를 드립니다." style={{ width: "100%" }} />
                    </div>
                    <div className="selected-date">
                        <a onClick={e => { e.preventDefault(); ChangeDate(-1) }} style={{ cursor: "pointer" }}>
                            <i className="fas fa-chevron-left mr10" aria-hidden="true"></i>
                        </a>
                        {date.getFullYear()}년 {date.getMonth() + 1}월
                        <a onClick={e => { e.preventDefault(); ChangeDate(1) }} style={{ cursor: "pointer" }}>
                            <i className="fas fa-chevron-right ml10" aria-hidden="true"></i>
                        </a>
                    </div>
                    <div className="point_info">
                        <Link to="/notice" type="button" name="view_policy" className="view_policy">포인트정책보기</Link>
                    </div>
                    <div className="date-navigation">
                        <ul>
                            <li>
                                <dl>
                                    <dt>출석 {currentDate}</dt>
                                    <dd>연속 <span className="point">{isEmpty(myAttendanceList) ? 0 : (isEmpty(myAttendanceList[myAttendanceList.length - 1].serialAtt) ? 0 : myAttendanceList[myAttendanceList.length - 1].serialAtt)}</span>일째</dd>
                                </dl>
                            </li>
                            {
                                dateArray.map((array, index) => {
                                    return (
                                        <Fragment key={index}>
                                            {
                                                array.map((day, dayIndex) => {
                                                    return day !== 0 ? (
                                                        <li className={`datepick ${date.getDate() === day && "active"}`} key={dayIndex}>
                                                            <dl>
                                                                <dt>{date.getDate() === day && <span className="today">today</span>}{day}</dt>
                                                                <dd>{myAttendanceList.findIndex(item => (Number(dateFormation(item.created_date, 10)) === day && Number(dateFormation(item.created_date, 11)) === date.getMonth() + 1)) >= 0 ? <img src='./imgs/Layer 1.png' alt='daily-check' style={{ filter: "invert(1)" }} /> : ""}</dd>
                                                            </dl>
                                                        </li>
                                                    )
                                                        :
                                                        ""

                                                }
                                                )
                                            }
                                        </Fragment>
                                    )
                                })
                            }
                        </ul>
                    </div>

                    <div className="wrapmemo">
                        <ul>
                            <div className="attendance_box text-center mb20 mt10">
                                <li><input type="text" name="attendTitle" value={attendTitle} placeholder='인사말' onChange={onChange} /></li>
                                <li>
                                    <button className="refresh" onClick={onReset}>
                                        <span className="fa fa-refresh" ></span>
                                    </button>
                                </li>
                                <li>
                                    <button className="ok_btn" onClick={onSubmit}>출석체크</button>
                                </li>

                            </div>
                        </ul>
                        <div className="desc">※ 출석체크 인사말 입력시 욕설이나, 비방 또는 홍보성 인사들 입력시 제재를 받으실 수 있습니다.</div>
                    </div>
                    <div className="alert alert-dismissible alert-warning alert-point-policy">
                        <button type="button" className="close alertclose">×</button>
                        <strong>포인트 정책</strong><br />
                        출석가능시간 : 00:00:00 ~ 23:59:59<br />
                        출석포인트 : 50점<br />7등포인트 : 출석포인트 + 70점<br />8등포인트 : 출석포인트 + 80점<br />9등포인트
                        : 출석포인트 + 90점<br />개근포인트 : 1000점, 10일 마다 지급<br /></div>


                    <div id="viewattendance"><table className="table table-hover table-bordered">
                        <thead>
                            <tr>
                                <th className="text-center">번호</th>
                                <th className="text-center">이름</th>
                                <th className="text-center">인사</th>
                                <th className="text-center">시간</th>
                                <th className="text-center">포인트</th>
                            </tr>
                        </thead>
                        <tbody>
                            {attendanceList &&
                                attendanceList.map((list, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="text-center">{Number(pageInfo.perPage) * (Number(pageInfo.curPage) - 1) + index + 1}</td>
                                            <td className="text-center">
                                                <div className="user_levelname">
                                                    <span className="lv_icon_box">
                                                        <div className={`lv_icon lv_icon${list.member.level > 6 ? 1 : list.member.level}`}></div>
                                                    </span>
                                                    <span className="user_name">{list.member.loginId}</span>
                                                </div>
                                            </td>
                                            <td><div className="att_memo">{list.title}</div></td>
                                            <td className="text-center">{dateFormation(list.created_date, 9)}</td>
                                            <td className="text-center">{list.attPoint}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                        <Pagination pageInfo={pageInfo} getList={getList} />
                    </div>
                </div>
                <SideBar />
            </div>
        </div>
    );
}