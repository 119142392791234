import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { RightSideBar, SideBar } from '../Layout';
import { Pagination } from '../Common';
import { communityActions, siteActions } from '../../store';
import { resource_url } from '../../Utils/keys';
import { SlotReviewVideoDetail } from './components';
import { isEmpty } from '../../Utils';


export { SlotReviewVideoContent };

function SlotReviewVideoContent() {

    const dispatch = useDispatch();
    const mainBanner = useSelector(state => state.site.mainBanner)
    const slotReviewVideo = useSelector(state => state.community.slotReviewVideo);

    const [showDetail, setShowDetail] = useState(false);
    const [active, setActive] = useState(0);

    const [pageInfo, setPageInfo] = useState({
        curPage: 1,
        totalPage: 1,
        perPage: 10,
    });
    const [searchInputs, setSearchInputs] = useState({
        searchText: "",
    });

    const { searchText } = searchInputs;

    const onChange = (e) => {
        const { name, value } = e.target;
        setSearchInputs({
            ...searchInputs,
            [name]: value
        })
        const param = {
            searchText: searchText,
        }
    }

    const onShowDetail = (index) => {
        setShowDetail(true)
        setActive(index)
        getAnswerList(1, 10, index)
    }
    const getAnswerList = (page = 1, perPage = 10, index = active) => {
        setPageInfo({
            ...pageInfo,
            curPage: page,
            perPage: Number(perPage)
        });
        const params = {
            type: "slotReviewVideo",
            typeId: slotReviewVideo[index].id,
            page: page,
            perPage: perPage,
        }
        dispatch(siteActions.getAnswerList(params));

    }

    const onBtnClick = () => {
        setShowDetail(!showDetail)
        setActive(-1)
    }

    const onSearch = () => {
        const param = {
            ...searchInputs
        }
        getList(1, pageInfo.perPage, param);
    }
    const onEnter = (e) => {
        if (e.key === "Enter") {
            onSearch();
        }
    }

    const getList = (page = 1, perPage = 10, search = searchInputs) => {
        setPageInfo({
            ...pageInfo,
            curPage: page,
            perPage: Number(perPage)
        });
        setSearchInputs(search)
        const params = {
            page: page,
            perPage: perPage,
            key: search.searchText,
        }
        dispatch(communityActions.getSlotReviewVideo(params));
        dispatch(siteActions.getMainBannerUrl());
    }

    useEffect(() => {
        getList();
    }, []);

    const videoUrl = resource_url + "/" + mainBanner.url
    const youtubeUrl = mainBanner.url

    return (
        <div className="container">
            <div className="row" style={{ margin: "0" }}>
                {showDetail &&
                    <SlotReviewVideoDetail
                        length={slotReviewVideo.length}
                        active={active}
                        onBtnClick={onBtnClick}
                        setActive={setActive}
                        slotReviewVideo={slotReviewVideo[active]}
                        pageInfo={pageInfo}
                        getList={getAnswerList} />
                }
                {!showDetail &&
                    <div className="main_area">
                        <div className="video_banner">
                            <video src={ mainBanner.status === 0 && (mainBanner.type === 2 ? videoUrl : youtubeUrl ) } muted autoPlay={true} loop={true}></video>
                        </div>
                        <div className="board">

                            <div className="board_form_box">
                                <div className="search_area">
                                    <div className="searchbox">
                                        <div>
                                            <span><input
                                                type="text"
                                                placeholder="검색"
                                                name="searchText"
                                                value={searchText}
                                                onChange={onChange}
                                                onKeyDown={onEnter}
                                            /></span>
                                            <span><button onClick={onSearch}><i className="fa fa-search" aria-hidden="true"></i></button></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="gallery_list review_video_list">
                                    <ul style={{ display: "flex", flexWrap: "wrap" }}>
                                        {slotReviewVideo &&
                                            slotReviewVideo.map((list, index) => {
                                                return (
                                                    <li key={index}>
                                                        <div className="box">
                                                            <div className="imgs cursor-pointer" title={list.title} onClick={() => onShowDetail(index)}>
                                                                <img src={`${resource_url}/${list.titleImg}`} />
                                                            </div>
                                                            <div className="txt">{list.content}</div>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                        {isEmpty(slotReviewVideo) &&
                                            <li>
                                                <div className="box">

                                                    <div className="txt" style={{ padding: "0px" }}>내역이 없습니다.</div>
                                                </div>
                                            </li>
                                        }

                                    </ul>
                                </div>
                            </div>
                            {!isEmpty(slotReviewVideo) &&
                                <Pagination pageInfo={pageInfo} getList={getList} />
                            }
                        </div>
                    </div>}
                <SideBar />
            </div>
        </div>
    );

}