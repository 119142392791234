import React, { Fragment, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import jwt_decode from "jwt-decode";

import { Footer } from "./Components/Layout";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { routers } from "./route";
import { ContentWrapper, PrivateRoute, Spinner } from "./Components/Common";
import { getIpAddr, history, isEmpty, setAuthToken } from "./Utils";
import { store, userActions } from "./store";
import { Home } from "./Containers/Home";
import { Register } from "./Components/Auth/Register";
import { initSocket } from "./Utils/socket";

export { App };

if (!isEmpty(localStorage.jwtToken)) {
  setAuthToken(localStorage.jwtToken);
  const currentTime = Date.now() / 1000;
  const decoded = jwt_decode(localStorage.jwtToken);

  store.dispatch(userActions.setUserInfo(decoded));
  store.dispatch(userActions.getUserInfo());
  localStorage.setItem("time", currentTime);
}
initSocket();

function App() {
  history.navigate = useNavigate();
  history.location = useLocation();
  return (
    <div className="App">
      <NotificationContainer />
      <Spinner />
      <Routes>
        {
          routers.map((router, index) => {
            if (router.private) {
              return (
                <Route key={index} path={router.pathname} element={
                  <PrivateRoute>
                    <ContentWrapper>
                      <router.component />
                    </ContentWrapper>
                  </PrivateRoute>
                } />
              )
            } else {
              return (
                <Route key={index} path={router.pathname} element={
                  <ContentWrapper>
                    <router.component />
                  </ContentWrapper>
                } />
              );
            }
          })
        }
      </Routes>
      <Footer />
    </div >
  );
}